import * as React from "react";

function SvgAdultLargeMaskBg(props) {
  return (
    <svg
      viewBox="0 0 380 380"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M255.441 55.6211C255.247 55.5102 255.025 55.4824 254.803 55.4547C253.777 55.427 252.695 56.3702 251.226 58.5062C249.09 61.6133 247.287 64.8312 245.678 68.3544C241.546 77.4813 238.412 88.1339 238.051 89.3268C237.968 89.6042 237.996 89.8261 238.135 90.0203C238.245 90.1868 238.467 90.2977 238.689 90.2977C238.856 90.2977 239.105 90.2422 239.355 89.9093C240.076 88.9107 246.621 79.8947 251.309 71.1008C253.139 67.6886 254.581 64.2764 255.746 60.6978C256.357 58.7282 256.911 56.3702 255.441 55.6211Z"
        fill="#91E7F2"
      />
      <path
        d="M258.603 52.7362C258.714 52.9026 258.936 53.0136 259.13 53.0136C259.352 53.0136 259.601 52.8749 259.74 52.7084C260.212 52.0981 262.597 48.8524 264.372 45.5789C265.093 44.2751 265.648 42.9712 266.119 41.5842C266.313 41.0016 266.785 39.6146 265.814 39.143C265.675 39.0875 265.537 39.032 265.398 39.032C264.843 39.032 264.344 39.4204 263.679 40.3636C262.847 41.5564 262.125 42.7771 261.515 44.1364C259.879 47.6318 258.658 51.6543 258.52 52.0981C258.464 52.2923 258.492 52.5697 258.603 52.7362Z"
        fill="white"
        fill-opacity="0.5"
      />
      <path
        d="M266.979 33.8443C267.201 33.8443 267.423 33.7056 267.561 33.5391C267.839 33.1785 270.418 29.794 272.304 26.4928C273.053 25.189 273.635 23.9129 274.107 22.5258C274.301 21.9433 274.8 20.5839 273.857 20.0846C273.719 20.0014 273.58 19.9736 273.414 19.9736C272.887 19.9736 272.36 20.3343 271.694 21.2497C270.834 22.4149 270.113 23.6355 269.447 24.9948C267.811 28.3238 266.563 32.1521 266.313 32.9011C266.258 33.0953 266.286 33.3727 266.397 33.5391C266.563 33.7333 266.757 33.8443 266.979 33.8443Z"
        fill="#91E7F2"
      />
      <path
        d="M269.17 44.0253C265.703 50.6832 262.93 58.5062 262.625 59.394C262.514 59.7269 262.597 59.9488 262.68 60.0875C262.791 60.2817 263.013 60.3927 263.235 60.3927C263.401 60.3927 263.623 60.3372 263.845 60.0598C264.427 59.3385 269.669 52.8748 273.552 46.4665C275.05 43.9976 276.27 41.5008 277.296 38.8377C277.879 37.3119 278.406 35.481 277.213 34.8152C277.019 34.7042 276.825 34.6487 276.603 34.6487C275.799 34.621 274.911 35.3145 273.719 36.8403C271.999 39.1151 270.501 41.4454 269.17 44.0253Z"
        fill="#3BB2F4"
      />
      <path
        d="M241.546 59.0888C241.379 59.0333 241.185 58.9778 241.019 58.9778C239.965 58.9501 238.911 59.9765 237.552 62.2791C235.666 65.5525 234.085 68.9092 232.782 72.5156C229.37 81.9199 227.013 92.7667 226.763 94.0151C226.708 94.2925 226.735 94.5144 226.874 94.6809C227.013 94.8473 227.207 94.9305 227.401 94.9583C227.595 94.9583 227.872 94.9028 228.094 94.5422C228.732 93.488 234.557 84.0282 238.578 74.8736C240.131 71.3227 241.324 67.8273 242.212 64.1655C242.711 62.1126 243.099 59.6991 241.546 59.0888Z"
        fill="white"
        fill-opacity="0.5"
      />
      <path
        d="M226.763 76.2886C226.874 76.4551 227.068 76.5383 227.29 76.5383C227.54 76.5383 227.817 76.3719 227.928 76.1499C228.205 75.6783 230.563 71.5171 232.199 67.4669C232.837 65.8857 233.281 64.3599 233.641 62.7231C233.78 62.0574 234.113 60.5038 233.031 60.1155C232.92 60.0877 232.809 60.06 232.698 60.06C231.894 60.0322 231.339 60.8922 230.896 61.7245C230.119 63.167 229.481 64.665 228.954 66.274C227.595 70.4075 226.763 75.1512 226.652 75.6783C226.597 75.8725 226.652 76.1222 226.763 76.2886Z"
        fill="#91E7F2"
      />
      <path
        d="M246.399 27.2419C246.649 27.2419 246.926 27.0755 247.037 26.8535C247.315 26.3819 249.672 22.2207 251.309 18.1705C251.947 16.617 252.39 15.0635 252.751 13.4268C252.89 12.761 253.222 11.2075 252.141 10.8191C252.03 10.7913 251.919 10.7636 251.808 10.7636C251.004 10.7359 250.449 11.5958 250.005 12.4558C249.228 13.8984 248.591 15.3964 248.064 17.0054C246.705 21.1388 245.873 25.8826 245.762 26.4097C245.734 26.6039 245.789 26.8535 245.9 27.02C246.011 27.1309 246.178 27.2142 246.399 27.2419Z"
        fill="#007FC8"
      />
      <path
        d="M231.506 33.3452C231.783 33.3452 232.06 33.1233 232.171 32.8736C232.532 32.0691 234.335 27.8524 235.472 23.7744C235.916 22.1377 236.221 20.5565 236.387 18.9197C236.443 18.2539 236.609 16.6449 235.5 16.3953C235.416 16.3675 235.361 16.3675 235.278 16.3675C234.418 16.3398 233.919 17.3107 233.558 18.2262C232.948 19.752 232.476 21.3055 232.116 22.9699C231.228 27.2144 230.896 32.0136 230.868 32.5684C230.84 32.7626 230.923 32.9845 231.034 33.1233C231.145 33.262 231.312 33.3452 231.506 33.3452Z"
        fill="#91E7F2"
      />
      <path
        d="M211.564 43.7759C211.065 48.0758 211.176 52.9028 211.204 53.4576C211.204 53.8182 211.509 54.1511 211.869 54.1789C212.174 54.1789 212.452 53.9569 212.563 53.6795C212.729 53.1802 214.227 48.6029 215.003 44.3307C215.308 42.6662 215.447 41.0572 215.447 39.4205C215.447 38.727 215.447 37.1457 214.338 36.9793C214.31 36.9793 214.227 36.9793 214.199 36.9793C213.284 36.9515 212.84 38.0612 212.563 39.0044C212.091 40.5024 211.758 42.0837 211.564 43.7759Z"
        fill="#91E7F2"
      />
      <path
        d="M220.162 20.3345C220.412 20.3345 220.717 20.2235 220.856 19.8351C221.161 19.0029 222.714 14.703 223.574 10.5973C223.934 8.96054 224.128 7.35154 224.184 5.71481C224.211 5.04902 224.267 3.44002 223.158 3.24584C223.13 3.24584 223.019 3.21809 222.991 3.21809C222.104 3.19035 221.632 4.18904 221.327 5.18772C220.8 6.74124 220.439 8.32249 220.19 9.98696C219.552 14.2869 219.524 19.0861 219.524 19.6132C219.469 19.9738 219.774 20.3345 220.162 20.3345Z"
        fill="#3BB2F4"
      />
      <path
        d="M133.268 69.9357C135.181 73.6808 137.788 77.4536 138.315 78.2026C138.426 78.3691 138.676 78.5355 138.898 78.5355C139.12 78.5355 139.314 78.4523 139.453 78.2581C139.591 78.0917 139.619 77.8143 139.563 77.5923C139.342 76.7601 138.094 72.3492 136.429 68.4655C135.764 66.912 134.987 65.4972 134.072 64.1101C133.379 63.0559 132.824 62.6121 132.269 62.6121C132.103 62.6121 131.936 62.6398 131.798 62.723C130.771 63.1946 131.243 64.7204 131.437 65.3862C131.909 66.9397 132.519 68.4377 133.268 69.9357Z"
        fill="#91E7F2"
      />
      <path
        d="M116.488 91.6848C115.462 90.3255 114.408 89.1326 113.187 87.9952C112.411 87.274 111.828 86.9411 111.329 86.9133C111.08 86.9133 110.83 86.9966 110.636 87.1353C109.748 87.8565 110.553 89.2159 110.913 89.7984C111.745 91.2132 112.688 92.5448 113.798 93.8209C116.543 97.0389 119.955 100.09 120.62 100.673C120.731 100.784 120.926 100.867 121.092 100.867C121.342 100.867 121.563 100.756 121.702 100.534C121.813 100.34 121.813 100.007 121.702 99.813C121.425 99.3692 119.095 95.1802 116.488 91.6848Z"
        fill="white"
        fill-opacity="0.5"
      />
      <path
        d="M58.438 79.5342C61.7385 82.3638 65.7879 84.9715 66.2316 85.2489C66.3426 85.3044 66.509 85.3876 66.6199 85.3876C66.8695 85.3876 67.1191 85.2489 67.2578 84.9992C67.3688 84.7773 67.3133 84.4444 67.1469 84.2225C66.8141 83.8063 63.8741 79.978 60.7678 76.9542C59.5474 75.7614 58.2994 74.7627 56.9403 73.8195C56.1638 73.2924 55.609 73.0427 55.1375 73.0427C54.6938 73.0427 54.4442 73.2369 54.3055 73.3756C53.5289 74.2079 54.5551 75.4562 54.9711 75.9833C56.0251 77.2594 57.1622 78.4245 58.438 79.5342Z"
        fill="#007FC8"
      />
      <path
        d="M46.6229 96.3455C46.0404 96.3455 45.7353 96.6229 45.6244 96.8171C44.9865 97.7603 46.1514 98.8422 46.6506 99.286C47.871 100.396 49.1468 101.394 50.5613 102.31C54.1946 104.64 58.5767 106.693 59.076 106.915C59.1592 106.943 59.2979 106.998 59.3811 106.998C59.6584 106.998 59.908 106.832 60.019 106.582C60.1299 106.332 60.019 105.972 59.8248 105.805C59.4365 105.417 55.9974 102.06 52.5027 99.4802C51.1437 98.4815 49.757 97.6216 48.287 96.9003C47.5936 96.5119 47.0666 96.3455 46.6229 96.3455Z"
        fill="#007FC8"
      />
      <path
        d="M79.0453 44.3306C81.5969 47.7151 84.8142 50.9608 85.4244 51.5988C85.5353 51.7375 85.7572 51.8208 85.9236 51.8208C86.1455 51.8208 86.3951 51.7098 86.5061 51.5156C86.6448 51.3214 86.617 51.0163 86.5338 50.7943C86.3119 50.3227 84.2318 45.9674 81.8743 42.3332C80.959 40.9184 79.9606 39.6423 78.8234 38.4495C78.0468 37.6172 77.4921 37.2843 76.9652 37.2566C76.7433 37.2566 76.5214 37.3121 76.3272 37.4508C75.412 38.1166 76.1054 39.5314 76.4382 40.1417C77.1593 41.612 78.0191 42.9713 79.0453 44.3306Z"
        fill="#91E7F2"
      />
      <path
        d="M43.461 80.311C46.9279 82.9186 51.1436 85.2489 51.6151 85.4986C51.6983 85.5541 51.8648 85.6095 51.9757 85.6095C52.253 85.6095 52.5027 85.4708 52.6136 85.2212C52.7245 84.9715 52.6413 84.6386 52.4749 84.4444C51.8925 83.7786 48.8416 80.3664 45.6243 77.6201C44.3485 76.5381 43.0172 75.5949 41.6027 74.7627C40.8539 74.3189 40.2992 74.0969 39.8554 74.0969C39.4671 74.0969 39.162 74.2356 38.9401 74.4853C38.219 75.373 39.3284 76.5382 39.7722 77.0375C40.8816 78.2026 42.102 79.2845 43.461 80.311Z"
        fill="#3BB2F4"
      />
      <path
        d="M180.14 31.1535C180.196 31.4587 180.501 31.7083 180.806 31.7361C181.166 31.7361 181.471 31.4309 181.499 31.0703C181.555 30.5432 182.109 25.7439 181.998 21.4163C181.943 19.7241 181.776 18.1151 181.444 16.5061C181.305 15.8681 181 14.37 179.946 14.3423C179.918 14.3423 179.89 14.3423 179.863 14.3423C178.726 14.3978 178.587 16.0068 178.531 16.6726C178.393 18.3093 178.393 19.946 178.559 21.6105C178.92 25.9381 180.001 30.6264 180.14 31.1535Z"
        fill="#91E7F2"
      />
      <path
        d="M188.1 14.259C188.156 14.5919 188.433 14.8693 188.766 14.8693C189.099 14.8693 189.404 14.5919 189.459 14.259C189.542 13.8152 190.263 9.82041 190.402 6.15856C190.458 4.74376 190.402 3.35669 190.208 1.99737C190.125 1.4148 189.931 0.0277413 188.877 0C187.823 0 187.629 1.38706 187.518 1.96963C187.296 3.35669 187.213 4.71602 187.268 6.13082C187.351 9.82041 188.017 13.8152 188.1 14.259Z"
        fill="white"
        fill-opacity="0.5"
      />
      <path
        d="M172.125 87.8843C172.236 88.134 172.485 88.3559 172.763 88.3559C172.957 88.3559 173.123 88.2727 173.262 88.1617C173.401 88.023 173.456 87.7733 173.456 87.6069C173.428 86.7192 173.206 82.1696 172.458 78.0084C172.152 76.3439 171.736 74.7904 171.154 73.2369C170.793 72.2937 170.35 71.3505 169.49 71.3228C169.407 71.3228 169.323 71.3228 169.24 71.3505C168.131 71.6002 168.242 73.1814 168.27 73.8472C168.381 75.4839 168.63 77.0929 169.046 78.7297C170.156 82.9186 171.931 87.3849 172.125 87.8843Z"
        fill="white"
        fill-opacity="0.5"
      />
      <path
        d="M161.557 7.9895C162.168 11.5959 163.36 15.4519 163.499 15.868C163.582 16.1454 163.859 16.3674 164.137 16.3951C164.497 16.3951 164.83 16.0622 164.83 15.7016C164.858 15.2577 164.997 11.2075 164.636 7.60112C164.497 6.18632 164.248 4.85473 163.859 3.52315C163.721 2.99607 163.36 1.74771 162.417 1.71997C162.389 1.71997 162.306 1.71997 162.279 1.71997C161.252 1.85868 161.225 3.24574 161.225 3.85605C161.225 5.21537 161.336 6.57469 161.557 7.9895Z"
        fill="#91E7F2"
      />
      <path
        d="M153.819 88.7442C153.93 88.9661 154.18 89.1326 154.43 89.1326C154.624 89.1326 154.818 89.0494 154.957 88.8829C155.095 88.7442 155.151 88.4668 155.123 88.2726C155.04 87.7455 154.263 82.974 152.96 78.8406C152.46 77.2316 151.85 75.7336 151.101 74.2633C150.658 73.431 150.131 72.571 149.354 72.571C149.243 72.571 149.104 72.5988 148.994 72.6265C147.912 72.9872 148.217 74.5684 148.356 75.2342C148.661 76.8432 149.104 78.3967 149.715 79.978C151.212 83.945 153.403 87.9675 153.819 88.7442Z"
        fill="white"
        fill-opacity="0.5"
      />
      <path
        d="M152.294 119.01C152.433 118.816 152.433 118.538 152.35 118.317C152.155 117.817 150.325 113.379 148.162 109.606C147.329 108.135 146.386 106.832 145.333 105.556C144.584 104.668 144.029 104.279 143.474 104.252C143.28 104.252 143.058 104.307 142.892 104.418C141.921 105.028 142.559 106.471 142.836 107.109C143.502 108.635 144.279 110.05 145.222 111.437C147.635 115.043 150.824 118.649 151.185 119.038C151.323 119.177 151.517 119.287 151.712 119.287C151.961 119.287 152.183 119.177 152.294 119.01Z"
        fill="#91E7F2"
      />
      <path
        d="M132.269 133.713C132.519 133.713 132.768 133.574 132.879 133.352C132.99 133.131 132.963 132.798 132.824 132.603C132.325 131.882 129.718 128.137 126.861 125.002C125.724 123.754 124.531 122.672 123.2 121.673C122.423 121.063 121.841 120.813 121.369 120.786C120.981 120.786 120.731 120.952 120.593 121.091C119.788 121.895 120.731 123.171 121.12 123.726C122.09 125.058 123.172 126.278 124.392 127.444C127.526 130.44 131.437 133.269 131.881 133.574C131.964 133.63 132.131 133.713 132.269 133.713Z"
        fill="white"
        fill-opacity="0.5"
      />
      <path
        d="M124.781 27.4361C126.445 31.4586 128.858 35.6198 129.135 36.0637C129.246 36.2579 129.496 36.4243 129.745 36.4243C129.939 36.4243 130.161 36.3411 130.272 36.1746C130.411 36.0082 130.466 35.7585 130.411 35.5366C130.245 34.6766 129.385 30.1825 128.026 26.1878C127.499 24.5788 126.833 23.1085 126.029 21.6659C125.391 20.5285 124.864 20.0292 124.254 20.0015C124.115 20.0015 124.004 20.0292 123.865 20.0569C122.811 20.4453 123.144 21.9988 123.283 22.6646C123.644 24.3291 124.143 25.8826 124.781 27.4361Z"
        fill="#91E7F2"
      />
      <path
        d="M92.9129 48.8247C95.4645 52.3201 98.8205 55.7877 99.2088 56.1761C99.3197 56.3148 99.5416 56.398 99.708 56.398C99.9299 56.398 100.18 56.2871 100.29 56.0929C100.429 55.8987 100.429 55.5935 100.318 55.3716C100.096 54.8723 98.0994 50.4891 95.7696 46.855C94.8544 45.4402 93.8836 44.1364 92.7742 42.9435C91.9976 42.1113 91.4429 41.7506 90.916 41.7229C90.6941 41.7229 90.4722 41.7784 90.3058 41.9171C89.3628 42.5551 90.0562 43.9977 90.3613 44.608C91.0824 46.0783 91.9144 47.4653 92.9129 48.8247Z"
        fill="#3BB2F4"
      />
      <path
        d="M191.817 94.6255C191.734 93.9597 191.512 92.3784 190.375 92.3784C189.237 92.3784 188.988 93.9319 188.905 94.6255C188.655 96.2622 188.544 97.8712 188.599 99.5634C188.71 103.891 189.487 108.663 189.57 109.19C189.626 109.522 189.903 109.8 190.236 109.8C190.569 109.8 190.874 109.522 190.929 109.19C191.012 108.663 191.872 103.947 192.039 99.5911C192.122 97.8712 192.039 96.2622 191.817 94.6255Z"
        fill="#3BB2F4"
      />
      <path
        d="M223.435 117.124C223.546 117.29 223.768 117.401 223.962 117.401C224.1 117.401 224.378 117.373 224.6 117.013C225.071 116.291 229.204 109.911 232.227 103.725C233.392 101.339 234.335 98.9532 235.084 96.4842C235.555 94.9307 235.888 93.2662 234.806 92.7946C234.668 92.7391 234.529 92.7114 234.362 92.6836C233.614 92.6559 232.865 93.3772 231.839 95.0139C230.48 97.2332 229.315 99.508 228.288 101.949C225.626 108.274 223.573 115.626 223.324 116.458C223.241 116.763 223.324 116.985 223.435 117.124Z"
        fill="#007FC8"
      />
      <path
        d="M248.147 55.954C248.258 56.1205 248.48 56.2314 248.674 56.2314C248.812 56.2314 249.09 56.2037 249.339 55.843C250.199 54.5947 254.609 48.02 257.826 41.4731C259.102 38.8654 260.101 36.2855 260.905 33.5668C261.349 31.9856 261.709 30.1269 260.489 29.5443C260.323 29.4611 260.156 29.4334 259.99 29.4334C259.158 29.4056 258.298 30.1546 257.188 31.8746C255.663 34.2603 254.387 36.7293 253.278 39.3925C250.421 46.3278 248.313 54.3728 248.091 55.2882C247.953 55.6211 248.064 55.8153 248.147 55.954Z"
        fill="#3BB2F4"
      />
      <path
        d="M224.905 97.483C224.766 97.4275 224.627 97.3998 224.461 97.3998C223.518 97.372 222.825 98.343 222.381 99.092C221.604 100.396 220.883 101.616 220.19 103.725C218.664 108.274 217.888 113.601 217.805 114.183C217.777 114.377 217.832 114.627 217.943 114.766C218.054 114.904 218.248 115.015 218.442 115.015C218.664 115.015 218.942 114.849 219.08 114.655C219.385 114.155 222.242 109.689 224.156 105.223C224.988 103.309 225.432 101.894 225.709 100.34C225.82 99.7578 226.153 97.9546 224.905 97.483Z"
        fill="#91E7F2"
      />
      <path
        d="M241.796 53.6517C242.045 53.6517 242.322 53.4852 242.433 53.2633C242.766 52.7085 245.623 47.826 247.509 43.0268C248.313 40.9739 248.757 39.4481 249.007 37.7837C249.09 37.1456 249.395 35.2315 248.064 34.7876C247.925 34.7321 247.786 34.7044 247.648 34.7044C246.649 34.6766 245.928 35.7586 245.484 36.563C244.68 37.9779 243.987 39.3094 243.293 41.5565C241.823 46.4667 241.185 52.1814 241.102 52.8194C241.074 53.0136 241.13 53.2633 241.269 53.402C241.435 53.5684 241.601 53.6517 241.796 53.6517Z"
        fill="white"
        fill-opacity="0.5"
      />
      <path
        d="M223.601 76.1221C223.463 76.0666 223.324 76.0388 223.158 76.0388C222.076 76.0111 221.05 77.093 219.829 79.5897C218.165 82.9742 216.834 86.4418 215.752 90.1314C213.062 99.508 211.509 109.939 211.204 111.992C211.176 112.241 211.204 112.463 211.37 112.63C211.481 112.768 211.675 112.879 211.869 112.879C212.036 112.879 212.341 112.824 212.563 112.408C213.506 110.632 218.387 101.256 221.688 92.0733C222.991 88.4392 223.934 84.8606 224.572 81.1432C224.96 79.0626 225.154 76.6214 223.601 76.1221Z"
        fill="#007FC8"
      />
      <path
        d="M230.868 56.1762C230.84 56.4258 230.896 56.62 231.034 56.7865C231.145 56.9252 231.339 57.0084 231.533 57.0361C231.672 57.0361 232.005 57.0084 232.227 56.5645C232.643 55.7323 236.332 48.2699 238.689 41.1681C239.604 38.4218 240.242 35.7031 240.658 32.9012C240.908 31.2922 240.991 29.3781 239.688 28.9897C239.577 28.962 239.466 28.9342 239.355 28.9342C238.439 28.9065 237.663 29.7665 236.748 31.7361C235.555 34.316 234.612 36.9237 233.891 39.7256C231.949 47.0215 230.979 55.2607 230.868 56.1762Z"
        fill="#007FC8"
      />
      <path
        d="M224.211 54.3451C223.407 55.9264 222.686 57.4244 222.048 59.9211C220.717 65.2197 220.245 71.2118 220.19 72.3492C220.162 72.5434 220.245 72.7653 220.356 72.904C220.495 73.0428 220.661 73.126 220.855 73.126C221.133 73.126 221.355 72.9873 221.521 72.7099C221.66 72.4602 224.738 66.7177 226.624 61.2527C227.401 58.9502 227.817 57.2857 228.011 55.4548C228.094 54.789 228.316 52.7084 226.902 52.2923C226.791 52.2645 226.652 52.2368 226.541 52.2368C225.432 52.2091 224.683 53.4297 224.211 54.3451Z"
        fill="white"
        fill-opacity="0.5"
      />
      <path
        d="M217.749 60.3371C217.888 60.3371 218.276 60.3094 218.47 59.7823C218.914 58.6449 222.963 48.2419 225.293 38.5602C226.181 34.7874 226.735 31.1533 226.957 27.3805C227.068 25.2999 227.013 22.8309 225.404 22.5258C225.321 22.498 225.238 22.498 225.127 22.498C223.989 22.4703 223.019 23.6632 222.048 26.3818C220.772 29.9327 219.829 33.5113 219.164 37.3119C217.472 47.16 217.111 58.2843 217.083 59.5326C217.083 59.6159 217.056 59.8933 217.278 60.1152C217.388 60.2539 217.583 60.3371 217.749 60.3371Z"
        fill="#007FC8"
      />
      <path
        d="M212.618 72.6543C211.647 75.3174 210.954 78.0083 210.455 80.8657C209.179 88.2449 208.929 96.5673 208.902 97.5105C208.902 97.7324 208.957 97.9266 209.096 98.093C209.207 98.2317 209.401 98.315 209.567 98.315C209.678 98.315 210.066 98.2872 210.261 97.7879C210.815 96.3731 213.672 88.9939 215.364 81.8921C216.03 79.0625 216.446 76.3161 216.612 73.4865C216.695 71.8498 216.64 69.9356 215.308 69.6582C215.225 69.6305 215.142 69.6305 215.087 69.6305C213.894 69.6027 213.145 71.2395 212.618 72.6543Z"
        fill="#91E7F2"
      />
      <path
        d="M207.154 94.986C207.126 94.3757 207.071 92.5448 205.74 92.3506C205.684 92.3506 205.629 92.3506 205.573 92.3506C204.492 92.3228 203.937 93.5434 203.632 94.4866C203.133 95.9292 202.716 97.2885 202.495 99.4801C201.995 104.279 202.411 109.633 202.467 110.216C202.495 110.577 202.8 110.909 203.16 110.909C203.438 110.909 203.743 110.688 203.854 110.41C203.937 110.188 205.823 104.973 206.738 100.09C207.071 98.0375 207.21 96.5672 207.154 94.986Z"
        fill="white"
        fill-opacity="0.5"
      />
      <path
        d="M176.784 110.216C176.895 110.438 177.172 110.66 177.422 110.66C177.616 110.66 177.783 110.577 177.921 110.466C178.06 110.327 178.143 110.105 178.143 109.939C178.143 109.883 178.032 104.224 177.145 99.2582C176.756 97.2054 176.368 95.7906 175.73 94.348C175.37 93.5158 174.787 92.4062 173.789 92.3784C173.678 92.3784 173.567 92.3784 173.484 92.4062C172.18 92.7113 172.263 94.5422 172.291 95.1525C172.346 96.6506 172.457 98.0931 173.012 100.229C174.177 104.696 176.34 109.328 176.784 110.216Z"
        fill="#91E7F2"
      />
      <path
        d="M157.425 96.3732C156.177 96.8171 156.454 98.6203 156.538 99.2306C156.759 100.729 157.037 102.143 157.841 104.196C159.561 108.552 162.251 112.907 162.75 113.739C162.861 113.933 163.111 114.1 163.36 114.1C163.555 114.1 163.749 114.017 163.887 113.878C164.026 113.739 164.081 113.49 164.054 113.268C164.026 113.046 163.277 107.525 161.835 102.781C161.225 100.784 160.67 99.4248 159.893 98.0655C159.477 97.3165 158.812 96.3455 157.869 96.3178C157.73 96.29 157.564 96.3178 157.425 96.3732Z"
        fill="#3BB2F4"
      />
      <path
        d="M141.2 125.418C141.311 125.529 141.505 125.612 141.671 125.612C141.893 125.612 142.143 125.501 142.254 125.307C142.393 125.113 142.393 124.808 142.282 124.586C142.171 124.364 139.785 119.426 136.873 115.32C135.681 113.628 134.71 112.491 133.545 111.437C132.741 110.715 132.103 110.355 131.52 110.355C131.215 110.355 130.91 110.438 130.688 110.632C129.634 111.437 130.466 113.073 130.744 113.628C131.437 114.988 132.131 116.236 133.545 117.928C136.513 121.507 140.423 124.808 141.2 125.418Z"
        fill="#3BB2F4"
      />
      <path
        d="M98.6541 95.4855C97.406 94.57 96.4907 94.1261 95.7696 94.0984C95.2427 94.0984 94.9098 94.3203 94.7434 94.4868C93.7727 95.4577 94.7989 97.0667 95.7696 98.3705C97.1841 100.229 98.5431 101.949 101.039 104.252C106.392 109.19 112.882 113.739 114.13 114.599C114.269 114.71 114.408 114.766 114.574 114.766C114.824 114.766 115.073 114.627 115.212 114.405C115.267 114.294 115.378 113.989 115.129 113.6C114.907 113.295 109.831 106.36 104.506 100.756C102.287 98.4538 100.596 96.928 98.6541 95.4855Z"
        fill="white"
        fill-opacity="0.5"
      />
      <path
        d="M122.146 45.6621C124.198 49.851 127.221 53.9844 127.804 54.7889C127.915 54.9554 128.164 55.0941 128.386 55.0941C128.58 55.0941 128.802 55.0109 128.941 54.8444C129.08 54.678 129.135 54.4283 129.08 54.2341C129.08 54.1786 127.915 48.6859 126.057 43.9421C125.308 42.0002 124.614 40.6964 123.727 39.3925C123.006 38.3384 122.34 37.8113 121.674 37.8113C121.48 37.8113 121.286 37.839 121.12 37.9222C119.899 38.4493 120.315 40.2525 120.454 40.8351C120.815 42.3054 121.175 43.6924 122.146 45.6621Z"
        fill="#007FC8"
      />
      <path
        d="M107.807 24.2738C109.277 27.0479 111.357 29.7665 111.773 30.2936C111.884 30.4601 112.106 30.571 112.328 30.571C112.549 30.571 112.744 30.4878 112.882 30.3214C113.021 30.1549 113.049 29.9052 113.021 29.711C112.993 29.5723 112.106 25.9382 110.802 22.8589C110.247 21.5551 109.776 20.6951 109.138 19.8074C108.583 19.0584 108.112 18.6978 107.585 18.6978C107.418 18.6978 107.252 18.7255 107.086 18.8087C106.115 19.2803 106.476 20.5842 106.586 21.028C106.836 22.0267 107.113 22.9422 107.807 24.2738Z"
        fill="white"
        fill-opacity="0.5"
      />
      <path
        d="M209.54 36.5075C209.817 36.5075 210.122 36.2855 210.233 36.0359C210.261 35.9804 212.174 30.7373 213.117 25.7161C213.506 23.6633 213.617 22.193 213.561 20.6117C213.533 20.0014 213.478 18.1705 212.147 17.9763C212.091 17.9763 212.036 17.9763 211.98 17.9763C210.899 17.9486 210.344 19.1692 210.039 20.1124C209.567 21.5549 209.124 22.9142 208.902 25.1058C208.43 29.7386 208.791 34.8707 208.874 35.8417C208.874 36.1746 209.179 36.5075 209.54 36.5075Z"
        fill="white"
        fill-opacity="0.5"
      />
      <path
        d="M194.812 87.0245C194.479 97.0113 195.616 108.08 195.755 109.328C195.811 109.883 196.227 110.05 196.448 110.05C196.532 110.05 197.031 110.022 197.17 109.384C197.475 108.191 200.054 97.3442 201.053 87.4406C201.441 83.5846 201.469 79.895 201.191 76.1221C201.025 74.0416 200.636 71.6281 198.972 71.5171H198.917C197.308 71.4894 196.615 73.7919 196.199 75.7893C195.395 79.4788 194.951 83.1684 194.812 87.0245Z"
        fill="#91E7F2"
      />
      <path
        d="M185.854 72.266C185.965 72.8763 186.464 72.9318 186.547 72.9318C186.769 72.9318 187.185 72.7931 187.268 72.2105C187.462 70.9899 189.154 59.9766 189.293 50.0175C189.348 46.1614 189.099 42.4719 188.489 38.7268C188.156 36.7017 187.573 34.3437 185.909 34.3159C184.273 34.3437 183.746 36.7294 183.468 38.81C182.997 42.5551 182.858 46.2447 183.052 50.1007C183.552 60.0876 185.604 71.0176 185.854 72.266Z"
        fill="#007FC8"
      />
      <path
        d="M163.555 78.7019C162.417 76.2052 161.447 75.0678 160.365 75.04C160.226 75.04 160.06 75.0678 159.921 75.0955C158.34 75.5394 158.451 77.9806 158.729 80.0612C159.228 83.8063 160.06 87.4126 161.225 91.1022C164.276 100.617 169.074 110.632 169.629 111.769C169.823 112.186 170.128 112.241 170.294 112.241C170.488 112.241 170.682 112.158 170.821 112.019C170.96 111.853 171.043 111.631 170.988 111.353C170.849 110.133 169.656 99.064 167.243 89.3823C166.3 85.6372 165.108 82.1418 163.555 78.7019Z"
        fill="#007FC8"
      />
      <path
        d="M141.588 46.4668C144.833 55.9265 149.853 65.8302 150.408 66.9676C150.602 67.3559 150.907 67.4392 151.074 67.4392C151.268 67.4392 151.462 67.3559 151.601 67.2172C151.739 67.0508 151.795 66.8288 151.767 66.5514C151.601 65.3308 150.186 54.2898 147.551 44.6636C146.525 40.9462 145.249 37.4509 143.641 34.0664C142.476 31.5974 141.477 30.5155 140.423 30.4878C140.257 30.4878 140.09 30.5155 139.952 30.5433C138.371 31.0149 138.537 33.4561 138.87 35.5367C139.453 39.2263 140.34 42.8327 141.588 46.4668Z"
        fill="#3BB2F4"
      />
      <path
        d="M110.719 68.6042C109.166 66.801 108.029 65.9965 107.086 65.9688C106.78 65.9688 106.475 66.052 106.226 66.2184C104.839 67.1061 105.643 69.4087 106.531 71.3228C108.084 74.7627 109.915 77.9807 112.106 81.171C117.625 89.216 124.725 97.039 126.112 98.537C126.167 98.6203 126.362 98.8144 126.694 98.8144C126.916 98.8144 127.166 98.7035 127.277 98.5093C127.388 98.3706 127.471 98.1209 127.305 97.7325C126.833 96.5951 122.479 86.3586 117.375 77.7865C115.351 74.4576 113.187 71.4615 110.719 68.6042Z"
        fill="#91E7F2"
      />
      <path
        d="M111.024 49.9899C116.099 58.5619 123.033 67.2727 123.838 68.2714C124.004 68.4933 124.198 68.6043 124.448 68.6043C124.67 68.6043 124.892 68.4933 125.03 68.3269C125.141 68.1882 125.224 67.9385 125.086 67.5779C124.697 66.4127 121.036 55.8988 116.516 46.9938C114.768 43.5539 112.799 40.4191 110.525 37.3953C109.027 35.4257 107.945 34.538 106.947 34.5103C106.669 34.5103 106.42 34.5657 106.17 34.7044C104.728 35.5089 105.394 37.8669 106.115 39.8088C107.474 43.332 109.055 46.6609 111.024 49.9899Z"
        fill="#3BB2F4"
      />
      <path
        d="M142.087 81.1155C141.866 81.1155 141.644 81.1432 141.449 81.2264C139.924 81.8922 140.368 84.278 140.923 86.3031C141.921 89.9372 143.197 93.4049 144.833 96.9003C149.104 105.944 155.178 115.265 155.872 116.291C156.066 116.597 156.316 116.68 156.51 116.68C156.732 116.68 156.926 116.597 157.064 116.43C157.203 116.236 157.259 116.014 157.175 115.709C156.898 114.516 154.263 103.697 150.602 94.4035C149.188 90.7972 147.523 87.496 145.554 84.278C144.168 82.1419 143.114 81.1432 142.087 81.1155Z"
        fill="white"
        fill-opacity="0.5"
      />
      <path
        d="M117.264 101.589C114.935 99.4802 113.437 98.9531 112.494 99.7576C111.218 100.812 112.3 103.003 113.382 104.779C115.351 107.997 117.542 110.993 120.093 113.878C126.694 121.396 135.126 128.636 136.069 129.469C136.124 129.524 136.319 129.69 136.596 129.69C136.846 129.69 137.067 129.552 137.206 129.358C137.289 129.219 137.4 128.941 137.151 128.553C136.54 127.471 131.021 117.817 124.919 109.911C122.562 106.859 120.038 104.141 117.264 101.589Z"
        fill="#007FC8"
      />
      <path
        d="M74.6631 71.9885C81.6247 79.118 90.4167 85.9701 91.4152 86.7469C91.4707 86.8023 91.6926 86.941 91.9144 86.941C92.1641 86.941 92.4137 86.8023 92.5246 86.5804C92.6078 86.4417 92.691 86.1365 92.4414 85.7482C91.7758 84.694 85.7572 75.3452 79.2672 67.7718C76.7433 64.8313 74.1084 62.2513 71.1962 59.8656C68.7555 57.8682 67.2578 57.4243 66.3426 58.2566C65.1222 59.3662 66.3148 61.5023 67.5075 63.25C69.6153 66.357 71.9728 69.2144 74.6631 71.9885Z"
        fill="white"
        fill-opacity="0.5"
      />
      <path
        d="M76.2719 81.4485C74.6355 80.2002 73.4429 79.6176 72.5554 79.5898C71.9452 79.5898 71.5846 79.8118 71.3627 80.0337C70.1979 81.1988 71.4459 83.2794 72.694 84.9716C74.9128 88.0232 77.3813 90.7973 80.1548 93.4605C87.3937 100.368 96.4076 106.86 97.4338 107.581C97.4893 107.636 97.6557 107.747 97.9053 107.747C98.1549 107.747 98.4046 107.609 98.5432 107.387C98.6264 107.248 98.7096 106.943 98.4323 106.554C97.7389 105.528 91.3876 96.4011 84.6202 89.0496C82.0131 86.22 79.295 83.7233 76.2719 81.4485Z"
        fill="#3BB2F4"
      />
      <path
        d="M64.4012 102.171C72.4999 108.024 82.3181 113.212 83.4553 113.822C83.594 113.906 83.7326 113.933 83.8436 113.933C84.1209 113.933 84.3705 113.767 84.4815 113.517C84.5369 113.379 84.6479 113.046 84.2873 112.657C83.4553 111.742 75.9113 103.558 68.2286 97.2053C65.2332 94.7364 62.2101 92.6558 58.9096 90.7971C57.3287 89.9094 56.1361 89.4655 55.304 89.4378C54.5274 89.41 54.0837 89.7707 53.8618 90.0481C52.8633 91.3519 54.3888 93.2383 55.8587 94.7641C58.4658 97.4828 61.2671 99.8963 64.4012 102.171Z"
        fill="#91E7F2"
      />
      <path
        d="M94.0224 119.759C93.0239 121.063 94.5771 122.949 96.047 124.447C98.6819 127.138 101.483 129.552 104.645 131.799C112.744 137.597 122.59 142.784 123.727 143.367C123.865 143.45 124.004 143.478 124.115 143.478C124.392 143.478 124.642 143.311 124.753 143.062C124.808 142.923 124.919 142.59 124.559 142.202C123.727 141.286 116.155 133.13 108.445 126.805C105.449 124.364 102.398 122.284 99.0979 120.425C97.517 119.537 96.3521 119.093 95.4923 119.093C94.688 119.121 94.2442 119.482 94.0224 119.759Z"
        fill="#91E7F2"
      />
      <path
        d="M161.363 43.748C163.499 53.5129 167.354 63.9436 167.798 65.1365C167.964 65.6081 168.325 65.6636 168.491 65.6636C168.685 65.6636 168.852 65.5804 168.991 65.4694C169.212 65.2475 169.212 64.9701 169.212 64.8314C169.212 63.583 169.046 52.4865 167.548 42.6106C166.966 38.7823 166.106 35.2037 164.886 31.6251C163.971 28.9064 163.055 27.7135 161.918 27.6858C161.807 27.6858 161.724 27.6858 161.613 27.7135C160.004 28.0187 159.866 30.4599 159.949 32.5405C160.088 36.2856 160.559 39.9752 161.363 43.748Z"
        fill="#3BB2F4"
      />
      <path
        d="M201.33 68.2157C201.413 68.2157 201.912 68.188 202.051 67.5777C202.328 66.3848 204.935 55.5657 205.878 45.6066C206.239 41.7506 206.294 38.061 205.989 34.2882C205.823 32.2076 205.407 29.7941 203.715 29.6831C202.106 29.6554 201.413 31.9579 200.997 33.983C200.22 37.6726 199.777 41.3344 199.638 45.2182C199.305 55.1773 200.47 66.2461 200.609 67.5222C200.692 68.0493 201.108 68.2157 201.33 68.2157Z"
        fill="#3BB2F4"
      />
      <path
        d="M205.074 85.4986C205.102 85.9702 205.435 86.2198 205.767 86.2198C206.017 86.2198 206.35 86.0811 206.489 85.6373C206.905 84.1947 209.123 76.5936 210.177 69.3532C210.593 66.4958 210.76 63.7217 210.677 60.8921C210.621 59.2553 210.399 57.3689 209.04 57.2025C209.012 57.2025 208.929 57.2025 208.929 57.2025C207.653 57.1747 207.015 58.9224 206.627 60.4205C205.906 63.1669 205.435 65.8855 205.185 68.7983C204.575 76.0665 204.963 83.9451 205.074 85.4986Z"
        fill="#007FC8"
      />
      <path
        d="M195.173 43.8867C195.45 43.8867 195.783 43.748 195.894 43.2486C196.227 41.7783 197.891 34.0385 198.446 26.7426C198.667 23.8575 198.64 21.0834 198.362 18.2537C198.196 16.617 197.835 14.7583 196.449 14.6751C195.117 14.6474 194.59 16.4783 194.285 18.0596C193.758 20.8337 193.481 23.6078 193.453 26.4929C193.37 33.7889 194.313 41.6396 194.507 43.1654C194.535 43.6647 194.868 43.8867 195.173 43.8867Z"
        fill="#91E7F2"
      />
      <path
        d="M149.631 23.6357C151.129 29.4059 153.653 35.5367 153.958 36.258C154.069 36.5077 154.346 36.7296 154.596 36.7296C154.79 36.7296 154.984 36.6464 155.095 36.5077C155.206 36.3967 155.317 36.2303 155.289 35.9251C155.262 35.1761 154.901 28.5459 153.792 22.6925C153.348 20.4177 152.765 18.2817 151.933 16.1733C151.462 14.9804 150.879 13.7321 149.853 13.7043C149.77 13.7043 149.659 13.7043 149.576 13.7321C148.272 14.0372 148.411 16.0624 148.494 16.9223C148.688 19.1971 149.049 21.3887 149.631 23.6357Z"
        fill="#007FC8"
      />
      <path
        d="M180.39 92.8501C181.249 100.091 183.246 107.775 183.635 109.245C183.746 109.661 184.051 109.828 184.3 109.828C184.633 109.828 184.994 109.606 185.021 109.107C185.105 108.191 185.798 99.8687 185.382 92.4063C185.216 89.5212 184.827 86.7471 184.189 84.0007C183.829 82.4749 183.274 80.7272 181.998 80.6995C181.943 80.6995 181.915 80.6995 181.86 80.6995C180.501 80.8104 180.223 82.6968 180.112 84.3336C179.974 87.1909 180.057 89.9651 180.39 92.8501Z"
        fill="#007FC8"
      />
      <path
        d="M134.932 99.3691C133.739 97.9265 132.879 97.2885 132.103 97.2607C131.853 97.2607 131.603 97.3162 131.382 97.4549C130.217 98.1485 130.799 99.9794 131.437 101.477C132.546 104.085 133.85 106.526 135.431 108.968C139.508 115.237 144.972 121.534 145.61 122.228C145.776 122.422 145.97 122.505 146.164 122.505C146.386 122.505 146.608 122.394 146.747 122.2C146.83 122.089 146.941 121.84 146.802 121.479C146.469 120.619 143.419 112.879 139.73 106.332C138.288 103.835 136.707 101.561 134.932 99.3691Z"
        fill="white"
        fill-opacity="0.5"
      />
      <path
        d="M128.109 138.179C128.359 138.179 128.608 138.041 128.747 137.791C128.802 137.68 128.913 137.347 128.636 136.986C127.721 135.794 122.867 129.524 117.68 124.392C115.6 122.367 113.465 120.591 111.135 118.982C109.887 118.122 108.972 117.706 108.278 117.706C107.724 117.706 107.391 117.928 107.197 118.122C106.281 119.121 107.335 120.702 108.361 121.978C110.136 124.17 112.078 126.167 114.269 128.082C119.927 132.992 126.916 137.514 127.693 138.041C127.804 138.152 127.942 138.179 128.109 138.179Z"
        fill="#3BB2F4"
      />
      <path
        d="M174.371 65.7469C175.176 72.4048 177.062 79.6175 177.283 80.4497C177.394 80.8659 177.699 81.0323 177.949 81.0323C178.282 81.0323 178.615 80.8104 178.67 80.311C178.753 79.4788 179.364 72.0441 179.003 65.3307C178.864 62.7231 178.504 60.2541 177.921 57.7851C177.589 56.3981 177.062 54.8168 175.897 54.7891C175.869 54.7891 175.786 54.7891 175.758 54.7891C174.482 54.9 174.233 56.62 174.149 58.118C173.983 60.6702 174.066 63.1392 174.371 65.7469Z"
        fill="#91E7F2"
      />
      <path
        d="M154.346 47.5485C153.098 47.8537 153.126 49.6014 153.293 51.0994C153.57 53.6238 154.041 56.0651 154.762 58.5618C156.621 64.9978 159.672 71.8221 160.032 72.5989C160.199 73.015 160.531 73.0705 160.67 73.0705C160.864 73.0705 161.058 72.9873 161.197 72.8485C161.308 72.7376 161.419 72.5434 161.391 72.2382C161.336 71.406 160.726 63.9713 159.283 57.4244C158.729 54.8722 157.98 52.5142 157.009 50.1562C156.232 48.3253 155.567 47.5208 154.707 47.5208C154.568 47.493 154.457 47.5208 154.346 47.5485Z"
        fill="white"
        fill-opacity="0.5"
      />
      <path
        d="M107.918 61.3083C111.662 66.8565 116.654 72.4325 117.209 73.0706C117.375 73.237 117.542 73.348 117.764 73.348C117.986 73.348 118.207 73.237 118.346 73.0428C118.429 72.9319 118.54 72.6822 118.374 72.2938C117.847 71.0455 115.129 64.4985 111.828 58.8393C110.525 56.5923 109.083 54.5672 107.418 52.6253C106.337 51.3492 105.532 50.7666 104.811 50.7666C104.562 50.7666 104.312 50.8221 104.118 50.9608C103.036 51.6266 103.591 53.2911 104.201 54.6504C105.255 56.9806 106.475 59.1445 107.918 61.3083Z"
        fill="white"
        fill-opacity="0.5"
      />
      <path
        d="M132.491 82.6136C131.326 80.2833 129.995 78.175 128.47 76.1499C127.416 74.7628 126.639 74.1525 125.89 74.1248C125.668 74.1248 125.446 74.1802 125.252 74.2635C124.143 74.8738 124.587 76.566 125.114 77.953C126.001 80.3388 127.083 82.5858 128.414 84.8329C131.826 90.6031 136.485 96.4565 137.012 97.1223C137.206 97.3719 137.428 97.4274 137.594 97.4274C137.816 97.4274 138.038 97.3165 138.177 97.15C138.26 97.039 138.371 96.7894 138.26 96.4287C137.789 95.1526 135.459 88.4392 132.491 82.6136Z"
        fill="#3BB2F4"
      />
      <path
        d="M95.9639 76.8988C94.799 75.9279 93.9947 75.4563 93.329 75.4563C92.9962 75.4563 92.6911 75.5673 92.4415 75.7615C91.4708 76.5937 92.3028 78.1472 93.1071 79.3956C94.4939 81.5316 96.0193 83.5013 97.8221 85.3877C102.287 90.1592 107.807 94.6533 108.861 95.5132C108.916 95.5687 109.083 95.7074 109.332 95.7074C109.582 95.7074 109.831 95.5687 109.942 95.3745C109.998 95.2636 110.137 94.9584 109.887 94.5978C109.443 93.8765 105.56 87.5238 101.289 82.3361C99.6249 80.311 97.8776 78.5356 95.9639 76.8988Z"
        fill="#91E7F2"
      />
      <path
        d="M70.0592 110.993C68.3951 110.133 67.2579 109.717 66.5091 109.717C65.8434 109.689 65.5383 109.994 65.3719 110.244C64.7063 111.27 65.9266 112.574 67.979 114.211C70.7803 116.458 73.6925 118.511 76.8543 120.453C85.0362 125.502 94.5771 130.19 95.6587 130.717C95.742 130.772 95.8806 130.828 96.047 130.828C96.3244 130.828 96.6017 130.661 96.7127 130.384C96.7682 130.245 96.8514 129.885 96.4353 129.524C95.5201 128.775 87.3937 121.978 79.4059 116.569C76.3273 114.461 73.2765 112.63 70.0592 110.993Z"
        fill="#007FC8"
      />
      <path
        d="M85.0638 108.108C90.2503 112.352 96.6294 116.236 97.3505 116.68C97.4614 116.735 97.6278 116.791 97.7388 116.818C98.0161 116.818 98.2658 116.68 98.3767 116.43C98.4322 116.319 98.5431 115.986 98.238 115.626C97.7111 114.987 92.9961 109.19 88.0869 104.612C86.1732 102.837 84.204 101.311 82.0684 99.924C80.9313 99.2027 80.1269 98.842 79.489 98.842C78.9343 98.842 78.6015 99.064 78.4351 99.2859C77.603 100.257 78.6015 101.672 79.5722 102.809C81.2641 104.723 83.0391 106.443 85.0638 108.108Z"
        fill="white"
        fill-opacity="0.5"
      />
      <path
        d="M86.4507 59.9212C87.7543 62.085 89.252 64.1101 90.9438 66.052C95.3537 71.1009 101.012 75.9834 101.65 76.5382C101.788 76.6769 101.955 76.7324 102.149 76.7324C102.398 76.7324 102.62 76.5937 102.759 76.3995C102.842 76.2608 102.925 75.9834 102.731 75.6505C102.315 74.9292 98.6265 68.4655 94.5217 63.1114C92.9408 61.0586 91.2489 59.1999 89.3629 57.5077C88.2258 56.4535 87.3937 55.9819 86.7281 55.9819C86.3953 55.9819 86.1179 56.0652 85.8683 56.2593C84.8976 57.0916 85.6741 58.6451 86.4507 59.9212Z"
        fill="#007FC8"
      />
      <path
        d="M135.819 56.8417C138.371 63.0558 142.115 69.5195 142.531 70.2408C142.725 70.5737 143.003 70.6292 143.141 70.6292C143.335 70.6292 143.557 70.5459 143.668 70.3795C143.779 70.2685 143.862 70.0466 143.807 69.7414C143.668 68.9369 142.282 61.6132 140.118 55.2328C139.286 52.7638 138.288 50.489 137.067 48.2419C136.152 46.5775 135.431 45.8562 134.627 45.8562C134.46 45.8562 134.322 45.8839 134.155 45.9394C132.963 46.3833 133.157 48.131 133.489 49.5735C134.1 52.0702 134.821 54.4283 135.819 56.8417Z"
        fill="#91E7F2"
      />
      <path
        d="M248.036 91.685C248.785 89.7431 249.478 87.3851 248.036 86.5806C247.814 86.4696 247.564 86.3864 247.315 86.3864C246.316 86.3586 245.179 87.2464 243.654 89.2437C241.352 92.2398 239.355 95.3468 237.58 98.7867C233.087 107.442 229.509 117.373 228.815 119.315C228.704 119.593 228.732 119.842 228.871 120.037C228.982 120.203 229.204 120.342 229.426 120.342C229.675 120.342 229.869 120.231 230.063 119.981C230.84 119.01 237.857 110.383 243.016 101.838C245.04 98.537 246.677 95.2081 248.036 91.685Z"
        fill="#3BB2F4"
      />
      <path
        d="M260.323 64.9977C260.128 64.8868 259.934 64.8313 259.712 64.8313C259.074 64.8036 258.409 65.2474 257.604 66.2184C256.634 67.3835 255.746 68.4931 254.748 70.4628C252.64 74.624 251.142 79.5064 250.837 80.4774C250.782 80.6716 250.809 80.949 250.92 81.1154C251.031 81.2819 251.253 81.3928 251.475 81.3928C251.669 81.3928 251.919 81.2819 252.057 81.1154C252.224 80.949 255.857 76.7323 258.464 72.5156C259.546 70.7402 260.212 69.4086 260.711 67.9383C260.905 67.4112 261.487 65.6635 260.323 64.9977Z"
        fill="#007FC8"
      />
      <path
        d="M194.673 70.8235C194.978 70.8235 195.283 70.5739 195.367 70.2687C195.533 69.6861 196.892 64.554 197.391 59.7548C197.613 57.6742 197.613 56.2039 197.419 54.6504C197.336 54.0401 197.114 52.2369 195.727 52.1536C194.424 52.1259 194.008 53.8736 193.869 54.4284C193.508 55.8987 193.203 57.2858 193.148 59.5051C193.065 64.1656 193.841 69.2423 194.008 70.2132C194.035 70.5184 194.34 70.8235 194.673 70.8235Z"
        fill="white"
        fill-opacity="0.5"
      />
      <path
        d="M188.572 78.258C188.738 83.0572 189.875 88.3003 190.014 88.9106C190.069 89.2158 190.374 89.4932 190.68 89.4932C191.012 89.4932 191.317 89.2158 191.373 88.9106C191.428 88.6887 192.565 83.3069 192.815 78.2857C192.926 76.2051 192.843 74.7348 192.565 73.1813C192.455 72.5987 192.149 70.7956 190.79 70.7678C189.459 70.7678 189.099 72.571 188.988 73.1536C188.738 74.6516 188.488 76.0664 188.572 78.258Z"
        fill="#3BB2F4"
      />
      <path
        d="M172.735 47.1323C172.846 47.5207 173.151 47.6594 173.4 47.6594C173.706 47.6594 174.066 47.4652 174.122 46.9936C174.122 46.9104 175.009 39.1983 174.649 32.152C174.51 29.2947 174.205 27.2696 173.595 25.189C173.234 23.9683 172.624 22.3316 171.293 22.2761C171.237 22.2761 171.182 22.2761 171.098 22.2761C169.462 22.4148 169.24 24.8006 169.185 25.5773C168.991 27.6579 168.88 29.5998 169.268 32.6236C170.155 39.0873 172.319 45.8285 172.735 47.1323Z"
        fill="white"
        fill-opacity="0.5"
      />
      <path
        d="M57.5506 123.282C56.5521 123.254 55.8587 123.587 55.5536 124.225C54.8325 125.696 56.7185 127.249 58.4381 128.442C61.5444 130.578 64.7617 132.381 68.2841 133.99C77.3812 138.096 88.0315 141.258 89.2519 141.619C89.3351 141.647 89.4183 141.675 89.5015 141.675C89.8066 141.675 90.0839 141.48 90.1949 141.203C90.2226 141.092 90.3335 140.676 89.862 140.343C88.8636 139.622 79.8774 133.103 71.0576 128.387C67.6462 126.556 64.2347 125.113 60.6569 123.948C59.3534 123.532 58.3272 123.31 57.5506 123.282Z"
        fill="#91E7F2"
      />
      <path
        d="M44.0713 118.178C47.5382 119.787 51.5875 121.035 52.0313 121.174C52.0868 121.202 52.1977 121.202 52.2532 121.202C52.5583 121.202 52.8356 121.007 52.9188 120.73C53.002 120.453 52.8911 120.12 52.6415 119.925C52.2532 119.648 48.8695 117.124 45.5135 115.293C44.21 114.571 42.9064 114.017 41.5197 113.545C40.965 113.351 40.5489 113.268 40.2161 113.268C39.495 113.24 39.1899 113.628 39.079 113.85C38.6075 114.793 39.7724 115.626 40.2993 115.986C41.4919 116.818 42.74 117.54 44.0713 118.178Z"
        fill="#3BB2F4"
      />
      <path
        d="M21.1897 107.941C22.3546 108.801 23.5749 109.523 24.9339 110.188C28.2622 111.825 32.0896 113.074 32.8385 113.323C32.8939 113.351 33.0049 113.351 33.0603 113.351C33.3654 113.351 33.615 113.157 33.726 112.907C33.8092 112.63 33.6983 112.297 33.4764 112.103C33.1158 111.825 29.7321 109.245 26.4316 107.359C25.1281 106.638 23.8523 106.027 22.4655 105.556C21.9108 105.362 21.467 105.251 21.1065 105.251C20.4131 105.223 20.108 105.583 19.9971 105.805C19.5256 106.749 20.6904 107.581 21.1897 107.941Z"
        fill="#91E7F2"
      />
      <path
        d="M36.8047 105.944C39.0512 107.664 41.381 109.162 43.9603 110.493C50.6168 113.961 58.4381 116.735 59.3257 117.04C59.4089 117.068 59.5198 117.096 59.603 117.096C59.8804 117.096 60.1854 116.929 60.2687 116.652C60.2964 116.541 60.4073 116.153 59.9913 115.82C59.2702 115.237 52.8079 109.994 46.401 106.11C43.9326 104.612 41.4364 103.392 38.7739 102.365C37.6922 101.949 36.8601 101.755 36.25 101.727C35.3347 101.699 34.9187 102.116 34.7523 102.448C34.0866 103.641 35.5011 104.945 36.8047 105.944Z"
        fill="white"
        fill-opacity="0.5"
      />
      <path
        d="M64.096 137.43C62.9866 137.153 62.0991 137.014 61.378 137.014C60.1299 136.986 59.3533 137.347 59.0482 138.096C58.4381 139.622 60.435 141.037 62.2378 142.091C65.5105 143.977 68.8665 145.559 72.4721 146.862C81.8466 150.275 92.7188 152.605 93.9669 152.882C94.0501 152.91 94.1056 152.91 94.161 152.91C94.4661 152.91 94.7712 152.716 94.8544 152.411C94.8821 152.327 94.9931 151.884 94.4384 151.551C93.3844 150.913 83.899 145.087 74.7741 141.064C71.2517 139.511 67.7571 138.318 64.096 137.43Z"
        fill="#3BB2F4"
      />
      <path
        d="M76.0777 151.745C75.3288 151.301 71.3349 149.054 67.3965 147.473C65.8156 146.835 64.2902 146.391 62.6538 146.03C62.2101 145.947 61.8218 145.891 61.489 145.891C60.5182 145.864 60.1577 146.28 60.019 146.64C59.6307 147.695 61.0452 148.471 61.6276 148.777C63.0699 149.553 64.5676 150.191 66.1762 150.718C70.3087 152.078 75.0515 152.91 75.5784 153.021C75.6062 153.021 75.6894 153.049 75.7171 153.049C76.0222 153.049 76.3273 152.855 76.4105 152.549C76.4937 152.3 76.4105 151.967 76.0777 151.745Z"
        fill="#91E7F2"
      />
      <path
        d="M12.3698 129.691C13.812 130.467 15.3097 131.105 16.9184 131.633C20.94 132.936 25.4331 133.769 26.3206 133.935C26.3483 133.935 26.4316 133.963 26.4593 133.963C26.7644 133.963 27.0695 133.769 27.1527 133.463C27.2359 133.158 27.0417 132.798 26.7921 132.659C26.0433 132.215 22.0494 129.968 18.111 128.387C16.5578 127.749 15.0047 127.305 13.3683 126.944C12.9245 126.861 12.5362 126.806 12.2034 126.806C11.2327 126.778 10.8721 127.194 10.7334 127.555C10.3729 128.609 11.7874 129.385 12.3698 129.691Z"
        fill="#3BB2F4"
      />
      <path
        d="M18.8877 143.311C18.6658 143.284 18.4439 143.284 18.2498 143.284C17.8337 143.284 16.5856 143.256 16.336 144.199C16.0587 145.309 17.5564 145.891 18.1665 146.141C19.692 146.751 21.2452 147.223 22.9093 147.583C27.1528 148.471 31.9509 148.804 32.5057 148.832C32.5057 148.832 32.5334 148.832 32.5611 148.832C32.8939 148.832 33.199 148.61 33.2822 148.277C33.3377 147.972 33.1436 147.611 32.8385 147.472C32.3392 147.251 27.9293 145.336 23.7413 144.171C22.1327 143.755 20.524 143.45 18.8877 143.311Z"
        fill="white"
        fill-opacity="0.5"
      />
      <path
        d="M38.857 167.113C40.4379 167.585 42.0188 167.918 43.7107 168.112C45.4025 168.306 47.344 168.417 49.5073 168.473C51.4765 168.528 52.9465 168.473 53.3625 168.473C53.6954 168.473 54.0282 168.223 54.0559 167.89C54.1114 167.557 53.8618 167.224 53.5567 167.113C53.0574 166.947 48.4534 165.449 44.2099 164.672C42.7399 164.395 41.3254 164.256 39.8555 164.228C39.6613 164.228 39.4672 164.228 39.273 164.228C38.5797 164.228 36.9988 164.228 36.8323 165.338C36.6937 166.475 38.2191 166.919 38.857 167.113Z"
        fill="white"
        fill-opacity="0.5"
      />
      <path
        d="M5.10322 158.375C6.65639 158.902 8.2373 159.263 9.90141 159.512C13.1464 159.984 16.6965 160.123 18.4993 160.178C19.0818 160.178 19.4423 160.206 19.5255 160.206C19.8583 160.206 20.1912 159.956 20.2466 159.651C20.3021 159.318 20.0525 158.985 19.7474 158.874C18.9154 158.569 14.6164 157.016 10.5116 156.156C8.87521 155.795 7.26657 155.601 5.63019 155.545H5.49151C4.90907 155.545 3.3559 155.49 3.16176 156.6C2.96761 157.654 4.46531 158.153 5.10322 158.375Z"
        fill="#91E7F2"
      />
      <path
        d="M63.7632 248.534C64.1515 248.534 64.6508 248.451 65.2887 248.257C66.8696 247.785 68.3673 247.175 69.865 246.398C73.637 244.484 77.3812 241.876 78.1301 241.349C78.3797 241.183 78.5184 240.85 78.4351 240.572C78.3519 240.295 78.1023 240.101 77.7972 240.073C77.7418 240.073 77.6031 240.101 77.5476 240.101C76.6878 240.323 72.2779 241.571 68.4227 243.235C66.8696 243.901 65.4551 244.678 64.0683 245.594C63.5136 245.982 62.1823 246.842 62.6538 247.868C62.737 248.146 63.0421 248.534 63.7632 248.534Z"
        fill="#3BB2F4"
      />
      <path
        d="M87.9484 266.51C87.4769 267.01 86.3952 268.175 87.1163 269.063C87.3104 269.312 87.6155 269.451 87.9761 269.451C88.4476 269.451 89.0023 269.257 89.8066 268.785C91.2211 267.953 92.5524 267.01 93.8282 265.9C97.0178 263.154 100.096 259.742 100.679 259.076C100.845 258.882 100.928 258.549 100.817 258.327C100.707 258.077 100.457 257.911 100.207 257.911C100.096 257.911 99.93 257.966 99.819 258.022C99.3475 258.271 95.1872 260.574 91.6649 263.237C90.2781 264.236 89.0578 265.318 87.9484 266.51Z"
        fill="white"
        fill-opacity="0.5"
      />
      <path
        d="M79.4614 321.272C82.2072 318.054 84.7033 314.225 85.1748 313.476C85.3135 313.282 85.3413 312.977 85.2303 312.755C85.1194 312.533 84.8975 312.395 84.6479 312.395C84.4815 312.395 84.2873 312.478 84.1764 312.561C83.7603 312.894 79.9606 315.834 76.9097 318.941C75.7171 320.162 74.7187 321.41 73.7757 322.77C73.3874 323.325 72.4998 324.656 73.3319 325.405C73.526 325.572 73.7757 325.683 74.0253 325.683C74.5245 325.683 75.1069 325.405 75.9113 324.739C77.1871 323.685 78.352 322.548 79.4614 321.272Z"
        fill="#91E7F2"
      />
      <path
        d="M106.808 319.94C106.697 319.746 106.475 319.635 106.254 319.607C106.115 319.607 105.921 319.635 105.699 319.857C105.089 320.495 101.899 323.768 99.4029 327.181C98.4045 328.54 97.5447 329.927 96.8236 331.397C96.5185 332.008 95.8251 333.422 96.7404 334.088C96.9068 334.199 97.1009 334.255 97.3228 334.282C97.8775 334.282 98.4322 333.922 99.2365 333.089C100.374 331.869 101.344 330.593 102.26 329.178C104.534 325.627 106.475 321.466 106.864 320.661C106.919 320.412 106.919 320.134 106.808 319.94Z"
        fill="#91E7F2"
      />
      <path
        d="M51.0883 293.087C50.9773 293.087 50.8387 293.114 50.7277 293.17C50.2562 293.392 45.9018 295.445 42.2685 297.83C40.854 298.746 39.5782 299.772 38.3856 300.882C37.8863 301.354 36.7214 302.435 37.3871 303.379C37.498 303.545 37.7754 303.823 38.3024 303.823C38.7461 303.823 39.3008 303.656 40.0774 303.268C41.5474 302.519 42.9341 301.659 44.2654 300.66C47.6491 298.108 50.8941 294.89 51.532 294.28C51.7262 294.085 51.8094 293.752 51.7262 293.503C51.6152 293.253 51.3656 293.087 51.0883 293.087Z"
        fill="#91E7F2"
      />
      <path
        d="M84.8419 327.042C84.6755 327.042 84.4536 327.125 84.3427 327.236C83.9544 327.597 80.3488 330.787 77.5198 334.088C76.4104 335.364 75.4951 336.696 74.6631 338.111C74.3303 338.693 73.5259 340.08 74.4135 340.774C74.5799 340.913 74.8018 340.996 75.0514 340.996C75.5783 340.996 76.1331 340.691 76.9651 339.914C78.1577 338.777 79.2394 337.584 80.2656 336.224C82.8727 332.757 85.2025 328.54 85.4521 328.068C85.563 327.874 85.5907 327.569 85.4521 327.347C85.3134 327.181 85.0915 327.042 84.8419 327.042Z"
        fill="#007FC8"
      />
      <path
        d="M14.2837 199.848C14.3391 200.986 15.9478 201.124 16.6134 201.18C17.2236 201.235 17.806 201.263 18.3885 201.263C19.4147 201.291 20.4964 201.235 21.5503 201.152C25.766 200.764 30.2314 199.765 31.0912 199.543C31.424 199.46 31.6736 199.155 31.6736 198.822C31.6459 198.489 31.3408 198.211 31.0357 198.184C30.6752 198.128 27.4579 197.768 23.9078 197.684C23.0203 197.657 22.1605 197.657 21.3839 197.684C19.692 197.74 18.0834 197.906 16.4748 198.239C15.7814 198.378 14.2282 198.711 14.2837 199.848Z"
        fill="#3BB2F4"
      />
      <path
        d="M6.10174 192.414C9.79053 192.33 13.7844 191.665 14.2282 191.581C14.561 191.526 14.8383 191.221 14.8383 190.888C14.8383 190.555 14.561 190.25 14.2559 190.194C13.8121 190.111 9.846 189.39 6.15721 189.251C4.68725 189.196 3.35596 189.251 1.99693 189.445C1.4145 189.529 0 189.723 0 190.777C0 191.831 1.38676 192.025 1.9692 192.136C2.96767 192.275 3.9384 192.358 4.93687 192.386C5.26969 192.441 5.68572 192.441 6.10174 192.414Z"
        fill="#91E7F2"
      />
      <path
        d="M73.0268 211.361C73.2487 211.361 73.4706 211.361 73.748 211.333C75.3843 211.222 76.993 210.973 78.6294 210.556C82.8728 209.502 87.3105 207.727 87.8097 207.505C88.1148 207.394 88.3367 207.061 88.2812 206.728C88.2257 206.395 87.9484 206.173 87.5878 206.173C87.5878 206.173 87.5324 206.173 87.5046 206.173C86.6171 206.201 82.0408 206.423 77.9082 207.172C76.2441 207.477 74.6909 207.893 73.1378 208.476C72.4999 208.726 71.0022 209.28 71.2518 210.39C71.3905 211.028 71.9729 211.361 73.0268 211.361Z"
        fill="#91E7F2"
      />
      <path
        d="M16.3083 215.439C16.2528 215.106 15.9477 214.856 15.6149 214.856C15.5594 214.856 15.2266 214.828 14.6719 214.828C13.1742 214.801 10.2343 214.773 7.51624 215.05C6.10174 215.189 4.77045 215.439 3.43916 215.827C2.9122 215.966 1.52544 216.382 1.66411 217.408C1.80279 218.435 3.18955 218.462 3.71652 218.462H3.82746C5.21422 218.49 6.57324 218.379 7.96 218.129C11.5378 217.519 15.393 216.326 15.8368 216.188C16.1141 216.104 16.3638 215.771 16.3083 215.439Z"
        fill="white"
        fill-opacity="0.5"
      />
      <path
        d="M73.9143 231.473C74.2471 231.473 74.6631 231.446 75.1623 231.335C76.771 231.03 78.3241 230.586 79.9051 229.975C83.8435 228.477 87.8651 226.286 88.6416 225.842C88.919 225.703 89.0854 225.343 89.0022 225.065C88.919 224.76 88.6417 224.538 88.3366 224.538C88.3088 224.538 88.2256 224.538 88.1702 224.538C87.6432 224.621 82.9005 225.398 78.7402 226.702C77.1315 227.201 75.6338 227.812 74.1639 228.561C73.5537 228.866 72.1392 229.615 72.5275 230.669C72.6662 231.057 72.999 231.473 73.9143 231.473Z"
        fill="#3BB2F4"
      />
      <path
        d="M105.311 237.327C105.727 237.327 106.281 237.188 107.002 236.855C108.5 236.189 109.942 235.412 111.329 234.469C114.962 232.028 118.54 228.838 118.929 228.505C119.151 228.311 119.234 227.978 119.151 227.728C119.067 227.478 118.79 227.284 118.513 227.284C118.429 227.284 118.263 227.312 118.18 227.34C117.375 227.673 113.132 229.42 109.471 231.529C108.001 232.389 106.697 233.304 105.422 234.358C104.895 234.802 103.674 235.829 104.284 236.799C104.451 236.994 104.728 237.299 105.311 237.327Z"
        fill="#007FC8"
      />
      <path
        d="M121.563 256.496C121.147 257.023 120.177 258.299 120.981 259.103C121.175 259.298 121.425 259.408 121.73 259.408C122.201 259.408 122.784 259.159 123.616 258.548C124.947 257.578 126.167 256.496 127.332 255.275C130.355 252.113 133.157 248.229 133.462 247.785C133.6 247.591 133.656 247.258 133.545 247.036C133.434 246.814 133.212 246.647 132.935 246.647C132.796 246.647 132.602 246.703 132.491 246.786C132.047 247.091 128.081 249.838 124.892 252.778C123.671 253.971 122.59 255.192 121.563 256.496Z"
        fill="white"
        fill-opacity="0.5"
      />
      <path
        d="M35.6952 249.255C35.6398 249.255 35.5565 249.255 35.5288 249.283C35.0018 249.394 30.2869 250.282 26.1543 251.669C24.5457 252.196 23.0757 252.862 21.6335 253.666C21.051 253.999 19.6366 254.748 20.0526 255.83C20.1635 256.163 20.4963 256.551 21.3561 256.579C21.7167 256.579 22.1327 256.524 22.6874 256.413C24.2961 256.052 25.8492 255.58 27.4024 254.942C31.4517 253.278 35.5843 250.864 36.028 250.587C36.3054 250.448 36.4718 250.088 36.3886 249.81C36.2777 249.477 36.0003 249.255 35.6952 249.255Z"
        fill="white"
        fill-opacity="0.5"
      />
      <path
        d="M56.3025 279.743C56.1915 279.493 55.9696 279.327 55.6923 279.299C55.5813 279.299 55.4427 279.327 55.3317 279.382C54.5274 279.743 50.3671 281.657 46.817 283.932C45.4025 284.847 44.099 285.818 42.9063 286.928C42.4071 287.4 41.2422 288.454 41.8801 289.397C41.9911 289.563 42.2684 289.841 42.8231 289.868C43.2669 289.868 43.8216 289.702 44.5705 289.341C46.0404 288.62 47.4272 287.788 48.7862 286.789C52.2809 284.237 55.7478 280.88 56.136 280.492C56.3025 280.325 56.4134 279.993 56.3025 279.743Z"
        fill="white"
        fill-opacity="0.5"
      />
      <path
        d="M109.11 188.724C108.583 188.641 103.896 187.781 99.4861 187.614C97.7388 187.559 96.1857 187.614 94.5493 187.864C93.8836 187.947 92.3027 188.169 92.3027 189.307C92.3027 190.444 93.8559 190.694 94.5493 190.777C95.7419 190.971 96.9068 191.054 98.0994 191.082C98.5432 191.082 99.0147 191.082 99.4861 191.082C103.868 190.971 108.583 190.194 109.11 190.111C109.443 190.056 109.72 189.75 109.72 189.418C109.72 189.085 109.415 188.807 109.11 188.724Z"
        fill="#3BB2F4"
      />
      <path
        d="M94.9098 147.833C97.1286 149.192 99.4029 150.358 101.844 151.384C108.167 154.047 115.517 156.1 116.349 156.35C116.405 156.377 116.515 156.377 116.571 156.377C116.876 156.377 117.153 156.183 117.264 155.906C117.292 155.823 117.403 155.379 116.904 155.074C116.183 154.602 109.776 150.469 103.619 147.472C101.233 146.307 98.8482 145.364 96.3797 144.615C95.4368 144.338 94.7156 144.171 94.1609 144.171C93.2179 144.143 92.8296 144.56 92.6632 144.893C92.1917 145.974 93.523 147.001 94.9098 147.833Z"
        fill="#91E7F2"
      />
      <path
        d="M31.2299 118.372C30.1205 118.344 29.649 118.816 29.4826 119.232C28.9279 120.48 30.4256 121.645 31.8123 122.533C34.1976 124.059 36.666 125.335 39.3286 126.445C46.2624 129.302 54.3056 131.41 55.2209 131.632C55.2763 131.66 55.3873 131.66 55.4427 131.66C55.7478 131.66 56.0252 131.466 56.1361 131.188C56.2193 130.939 56.1638 130.606 55.8033 130.356C55.0267 129.829 48.1206 125.169 41.4365 121.867C38.8293 120.591 36.25 119.593 33.5319 118.788C32.5612 118.511 31.8123 118.372 31.2299 118.372Z"
        fill="#007FC8"
      />
      <path
        d="M114.574 160.594C114.38 160.456 109.72 157.487 105.144 155.518C103.23 154.685 101.816 154.241 100.263 153.964C99.8744 153.881 99.5138 153.853 99.2088 153.853C98.0161 153.825 97.5446 154.352 97.3782 154.769C96.9067 156.017 98.4599 156.988 98.9869 157.293C100.29 158.07 101.511 158.791 103.619 159.485C108.056 160.983 113.104 161.732 114.075 161.87C114.103 161.87 114.158 161.87 114.186 161.87C114.491 161.87 114.796 161.676 114.879 161.371C114.99 161.094 114.851 160.761 114.574 160.594Z"
        fill="white"
        fill-opacity="0.5"
      />
      <path
        d="M42.9619 132.132C40.9095 131.327 39.384 130.883 37.7199 130.634C35.9726 130.384 35.0296 130.689 34.7245 131.577C34.2807 132.881 35.9449 133.852 36.4996 134.157C37.9141 134.961 39.2453 135.655 41.4919 136.348C46.401 137.819 52.1145 138.457 52.7524 138.54C52.7801 138.54 52.8356 138.54 52.8356 138.54C53.1407 138.54 53.4457 138.346 53.529 138.041C53.6122 137.736 53.4457 137.375 53.1961 137.236C53.002 137.097 47.9265 134.074 42.9619 132.132Z"
        fill="#91E7F2"
      />
      <path
        d="M81.0421 155.074C80.1824 154.935 79.4335 154.852 78.8233 154.824C78.2409 154.824 76.4658 154.769 76.0498 156.072C75.5506 157.654 77.6307 158.902 79.5167 159.845C82.9004 161.51 86.3673 162.841 90.0561 163.923C99.6525 166.697 110.663 168.279 111.911 168.473C111.939 168.473 112.022 168.473 112.05 168.473C112.355 168.473 112.688 168.251 112.771 167.946C112.799 167.862 112.882 167.391 112.327 167.086C111.246 166.503 101.372 161.343 91.9975 157.959C88.3365 156.655 84.7587 155.712 81.0421 155.074Z"
        fill="#007FC8"
      />
      <path
        d="M31.6737 142.951C34.253 144.144 36.8602 145.087 39.6614 145.808C46.9003 147.722 55.1654 148.693 56.1084 148.804C56.1361 148.804 56.1916 148.804 56.2193 148.804C56.5244 148.804 56.8572 148.582 56.9404 148.277C56.9959 148.028 56.9404 147.667 56.4967 147.445C55.1377 146.779 48.0374 143.284 41.1036 140.981C38.3579 140.066 35.6398 139.428 32.8386 139.012C32.2561 138.928 31.7569 138.873 31.3131 138.873C30.8139 138.873 29.2607 138.817 28.9279 139.983C28.5396 141.286 30.2037 142.257 31.6737 142.951Z"
        fill="#007FC8"
      />
      <path
        d="M54.6383 151.634C53.2793 151.606 52.475 151.994 52.2531 152.799C51.8371 154.214 53.6953 155.157 54.3055 155.462C55.8864 156.267 57.3841 156.988 59.8803 157.626C65.1777 158.957 71.1685 159.429 72.3056 159.484C72.3334 159.484 72.3611 159.484 72.3611 159.484C72.6662 159.484 72.999 159.263 73.0822 158.957C73.1654 158.652 72.9713 158.292 72.6939 158.153C72.6385 158.125 66.7863 154.963 61.2116 153.049C58.9095 152.272 57.2454 151.856 55.4149 151.661C55.1098 151.634 54.8602 151.634 54.6383 151.634Z"
        fill="white"
        fill-opacity="0.5"
      />
      <path
        d="M27.2915 152.716C27.0141 152.688 26.7645 152.688 26.5149 152.688C24.9062 152.66 22.7429 152.771 22.4378 154.269C22.105 155.878 24.3238 156.905 26.293 157.626C29.8431 158.902 33.4209 159.845 37.2207 160.511C47.0667 162.203 58.1885 162.564 59.4366 162.592H59.4643C59.9635 162.592 60.1854 162.286 60.2409 162.037C60.2964 161.759 60.1854 161.399 59.6862 161.204C58.549 160.761 48.1483 156.71 38.4688 154.408C34.7245 153.492 31.0634 152.938 27.2915 152.716ZM59.492 161.26V161.843L59.4643 161.26H59.492Z"
        fill="#3BB2F4"
      />
      <path
        d="M69.5877 164.339C69.3104 165.671 71.0577 166.475 72.5831 167.03C75.2457 168.001 77.936 168.695 80.7927 169.194C88.1703 170.47 96.4909 170.72 97.4339 170.747H97.4616C97.9054 170.747 98.155 170.47 98.2104 170.193C98.2659 169.915 98.1272 169.555 97.7112 169.388C96.8514 169.055 89.1133 166.031 81.819 164.284C78.99 163.618 76.2442 163.202 73.4152 163.035C73.1933 163.035 72.9992 163.008 72.8327 163.008C72.0007 163.008 69.8651 162.952 69.5877 164.339ZM97.4893 169.416V170.026V169.416C97.4616 169.416 97.4893 169.416 97.4893 169.416Z"
        fill="#3BB2F4"
      />
      <path
        d="M110.802 176.629C110.858 176.323 110.608 175.963 110.331 175.852C110.109 175.769 104.895 173.882 100.013 172.967C97.933 172.578 96.4353 172.467 94.9098 172.523C94.2997 172.551 92.4691 172.606 92.275 173.938C92.0808 175.269 93.8282 175.852 94.3829 176.046C95.8251 176.545 97.1841 176.962 99.3752 177.183C100.54 177.294 101.816 177.378 103.203 177.405C106.586 177.489 109.554 177.267 110.109 177.211C110.442 177.211 110.774 176.934 110.802 176.629Z"
        fill="white"
        fill-opacity="0.5"
      />
      <path
        d="M94.2442 203.982C93.6895 204.231 91.9976 204.953 92.3027 206.257C92.4691 207.033 93.218 207.449 94.5215 207.477C94.688 207.477 94.8544 207.477 95.0485 207.477C96.5462 207.422 97.9885 207.311 100.124 206.756C104.645 205.535 109.277 203.371 110.164 202.955C110.442 202.817 110.663 202.484 110.58 202.179C110.525 201.873 110.192 201.596 109.859 201.624C109.804 201.624 104.146 201.735 99.1811 202.622C97.1287 202.955 95.7142 203.371 94.2442 203.982Z"
        fill="#3BB2F4"
      />
      <path
        d="M102.703 217.852C100.706 218.463 99.3475 219.017 97.9884 219.794C97.4615 220.099 95.8806 221.015 96.3243 222.263C96.463 222.707 96.9068 223.206 98.0439 223.234C98.3767 223.234 98.7373 223.206 99.181 223.151C100.679 222.929 102.093 222.652 104.146 221.847C108.611 220.072 113.187 217.242 113.687 216.937C113.936 216.77 114.103 216.438 114.019 216.132C113.936 215.799 113.548 215.578 113.215 215.633C112.965 215.661 107.446 216.41 102.703 217.852Z"
        fill="white"
        fill-opacity="0.5"
      />
      <path
        d="M124.864 237.327C124.753 237.327 124.614 237.354 124.503 237.41C124.448 237.438 119.4 239.851 115.24 242.819C113.548 244.012 112.411 244.983 111.357 246.148C110.941 246.592 109.72 247.952 110.553 249.033C110.719 249.255 111.08 249.533 111.662 249.561C112.161 249.561 112.771 249.394 113.548 248.978C114.907 248.284 116.155 247.591 117.847 246.176C121.508 243.097 124.975 238.963 125.363 238.492C125.53 238.298 125.585 237.965 125.502 237.715C125.363 237.493 125.114 237.327 124.864 237.327Z"
        fill="#3BB2F4"
      />
      <path
        d="M94.4383 284.958C94.688 285.208 95.0208 285.319 95.3813 285.347C96.1025 285.375 97.0455 284.903 98.3213 283.932C100.18 282.517 101.899 281.158 104.201 278.661C109.138 273.307 113.687 266.816 114.546 265.567C114.796 265.234 114.713 264.929 114.63 264.79C114.519 264.569 114.269 264.43 114.019 264.402C113.77 264.402 113.603 264.513 113.548 264.569C113.465 264.624 106.392 269.812 100.706 275.193C98.3767 277.413 96.8513 279.105 95.4091 281.047C94.4661 282.351 93.4676 283.987 94.4383 284.958Z"
        fill="#91E7F2"
      />
      <path
        d="M39.3562 259.409C39.7445 259.409 40.1882 259.353 40.7707 259.215C42.2406 258.854 43.6274 258.493 45.5966 257.522C49.7846 255.469 53.9172 252.446 54.7215 251.863C54.9434 251.697 55.082 251.336 54.9988 251.059C54.9156 250.781 54.6383 250.587 54.3332 250.587C54.2777 250.587 54.1945 250.587 54.139 250.615C54.0836 250.615 48.5365 251.808 43.8493 253.638C41.9078 254.387 40.6043 255.081 39.3007 255.969C38.8015 256.302 37.276 257.356 37.8308 258.576C38.0249 258.965 38.4132 259.409 39.3562 259.409Z"
        fill="#91E7F2"
      />
      <path
        d="M29.6489 266.677C29.6212 266.677 25.9324 267.565 22.7983 268.896C21.4948 269.451 20.635 269.923 19.7475 270.561C19.3869 270.838 18.2775 271.615 18.749 272.614C18.8599 272.863 19.165 273.252 19.9139 273.28C20.1912 273.28 20.5518 273.224 20.9678 273.113C21.9663 272.863 22.8815 272.586 24.2128 271.865C26.9864 270.394 29.7044 268.314 30.2314 267.898C30.4533 267.731 30.5919 267.398 30.481 267.121C30.37 266.816 29.9818 266.594 29.6489 266.677Z"
        fill="#007FC8"
      />
      <path
        d="M17.917 167.557C17.7228 168.889 19.4701 169.471 20.0248 169.666C21.4671 170.165 22.8261 170.581 25.0172 170.803C26.182 170.914 27.4579 170.997 28.8446 171.025C32.2006 171.108 35.1683 170.886 35.7507 170.831C36.0558 170.803 36.3886 170.553 36.4163 170.22C36.4718 169.915 36.2222 169.555 35.9448 169.444C35.8894 169.416 30.6474 167.502 25.6273 166.559C23.5472 166.17 22.0495 166.059 20.5241 166.115C19.9416 166.142 18.1111 166.226 17.917 167.557Z"
        fill="#007FC8"
      />
      <path
        d="M109.304 182.51C108.112 182.205 97.2673 179.625 87.3658 178.626C85.674 178.46 83.9821 178.349 82.3457 178.321C80.2379 178.265 78.1855 178.321 76.0776 178.487C73.9974 178.654 71.5845 179.042 71.4735 180.707C71.3626 182.343 73.6924 183.065 75.7448 183.508C79.4335 184.285 83.0946 184.757 86.9775 184.868C87.3935 184.896 87.8096 184.896 88.2256 184.896C97.8775 185.117 108.112 184.036 109.277 183.925C109.776 183.869 109.97 183.536 109.998 183.259C110.025 182.981 109.831 182.621 109.304 182.51Z"
        fill="#3BB2F4"
      />
      <path
        d="M34.2529 193.773C34.2807 195.41 36.6659 195.937 38.746 196.214C40.9648 196.491 43.1282 196.658 45.3193 196.713C46.8724 196.741 48.4534 196.713 50.0343 196.658C60.0467 196.159 70.9466 194.106 72.1669 193.856C72.6662 193.773 72.8326 193.412 72.8326 193.135C72.8326 192.857 72.6384 192.497 72.1115 192.441C70.9466 192.247 60.435 190.638 50.6444 190.416H49.9233C46.0681 190.361 42.3793 190.61 38.6351 191.221C36.5827 191.526 34.2252 192.136 34.2529 193.773Z"
        fill="#91E7F2"
      />
      <path
        d="M75.0514 219.766C75.412 221.07 77.1316 221.098 77.714 221.126C78.3519 221.153 79.1285 221.098 80.016 220.959C83.7603 220.46 87.3659 219.628 91.0546 218.463C100.318 215.494 109.859 210.945 111.717 210.057C112.272 209.807 112.189 209.336 112.189 209.225C112.133 208.92 111.801 208.67 111.495 208.67C111.468 208.67 111.412 208.67 111.301 208.67C110.081 208.809 99.0146 210.002 89.335 212.415C85.5908 213.358 82.0962 214.551 78.657 216.105C76.7155 216.992 74.5799 218.185 75.0514 219.766Z"
        fill="#007FC8"
      />
      <path
        d="M66.8973 229.282C67.4242 229.004 67.3688 228.56 67.341 228.449C67.2855 228.144 66.9527 227.922 66.6476 227.895C66.5922 227.895 66.5367 227.895 66.4535 227.922C64.4566 228.172 53.9727 229.559 44.5704 232.139C40.8539 233.165 37.3593 234.442 33.9478 236.05C32.0619 236.938 29.954 238.187 30.4255 239.768C30.6751 240.6 31.5349 241.044 32.9494 241.072C33.615 241.099 34.4471 241.016 35.4178 240.877C39.1343 240.295 42.7399 239.407 46.4009 238.159C55.8586 234.858 65.7878 229.836 66.8973 229.282Z"
        fill="#007FC8"
      />
      <path
        d="M67.6462 274.25C68.4782 274.278 69.6708 273.917 71.2517 273.224C74.6909 271.67 77.9082 269.839 81.0977 267.648C89.3351 261.989 97.5447 254.471 98.46 253.638C98.8205 253.306 98.7651 252.945 98.7096 252.806C98.6264 252.556 98.349 252.362 98.0717 252.362C97.9607 252.362 97.8221 252.39 97.6556 252.446C95.7974 253.222 86.0623 257.383 77.714 262.377C74.3858 264.347 71.3904 266.51 68.5337 269.007C66.9528 270.394 65.2609 272.142 66.1484 273.529C66.3703 273.834 66.7864 274.222 67.6462 274.25Z"
        fill="#007FC8"
      />
      <path
        d="M49.9233 268.674C58.4935 263.597 67.2024 256.662 68.2008 255.858C68.6169 255.525 68.5336 255.164 68.5059 255.025C68.4227 254.748 68.1453 254.554 67.868 254.554C67.7571 254.554 67.6461 254.581 67.5074 254.609C66.3426 254.998 55.8587 258.632 46.9279 263.181C43.4888 264.929 40.3547 266.899 37.3316 269.173C35.6674 270.449 33.8369 272.058 34.6412 273.529C34.8354 273.917 35.3069 274.361 36.3054 274.389C37.1374 274.416 38.2468 274.139 39.7445 273.584C43.2669 272.225 46.5951 270.644 49.9233 268.674Z"
        fill="white"
        fill-opacity="0.5"
      />
      <path
        d="M81.181 238.242C81.4584 238.908 82.124 239.241 83.1502 239.269C83.9268 239.296 84.953 239.13 86.2566 238.769C89.8899 237.771 93.3568 236.494 96.8514 234.858C105.671 230.696 114.546 224.954 116.266 223.817C116.738 223.512 116.654 223.095 116.627 222.984C116.543 222.707 116.266 222.485 115.961 222.485C115.878 222.485 115.795 222.485 115.656 222.513C113.687 222.984 103.452 225.509 94.3553 229.088C90.7497 230.502 87.4492 232.167 84.2319 234.136C82.4846 235.274 80.5154 236.716 81.181 238.242Z"
        fill="#91E7F2"
      />
      <path
        d="M129.385 243.624C129.69 243.291 129.607 242.958 129.551 242.819C129.44 242.57 129.191 242.403 128.941 242.403C128.747 242.403 128.58 242.487 128.47 242.542C127.388 243.152 117.736 248.673 109.832 254.776C106.781 257.134 104.063 259.631 101.511 262.433C100.096 263.986 98.6265 265.928 99.6804 267.204C99.9023 267.454 100.291 267.759 100.956 267.787C101.844 267.814 103.036 267.343 104.728 266.316C107.946 264.347 110.941 262.155 113.825 259.603C121.342 253 128.581 244.595 129.385 243.624Z"
        fill="#3BB2F4"
      />
      <path
        d="M86.8112 287.483C86.7003 287.261 86.4507 287.094 86.201 287.094C85.9792 287.094 85.8405 287.178 85.7018 287.261C84.6479 287.927 75.3011 293.919 67.7017 300.438C64.7617 302.963 62.1824 305.598 59.7971 308.511C58.4658 310.12 57.0791 312.145 58.1885 313.366C58.3826 313.587 58.7709 313.865 59.3811 313.865C60.2686 313.893 61.489 313.366 63.1808 312.2C66.3149 310.092 69.1716 307.734 71.9452 305.043C79.1008 298.08 85.9237 289.286 86.7003 288.287C86.9499 287.927 86.8667 287.622 86.8112 287.483Z"
        fill="#91E7F2"
      />
      <path
        d="M79.9607 308.344C80.238 308.649 80.6263 308.788 81.0978 308.816C81.9854 308.844 83.2057 308.261 84.8976 307.013C87.9484 304.793 90.7219 302.352 93.3845 299.55C100.291 292.31 106.781 283.294 107.502 282.268C107.751 281.907 107.668 281.602 107.613 281.463C107.502 281.241 107.252 281.075 107.002 281.075C106.753 281.075 106.559 281.213 106.476 281.269C104.811 282.406 96.158 288.482 88.9746 295.084C86.1456 297.692 83.6495 300.438 81.3475 303.434C80.0994 305.099 78.7958 307.151 79.9607 308.344Z"
        fill="#3BB2F4"
      />
      <path
        d="M113.714 296.277C113.909 295.944 113.825 295.667 113.742 295.5C113.631 295.306 113.409 295.167 113.16 295.167C112.855 295.167 112.66 295.334 112.577 295.417C111.662 296.249 103.48 303.795 97.1287 311.479C94.6603 314.475 92.5801 317.499 90.7219 320.8C89.6957 322.631 88.6695 324.85 89.973 325.849C90.2227 326.043 90.5555 326.154 90.8883 326.154C91.8313 326.182 93.0239 325.433 94.688 323.852C97.4061 321.216 99.819 318.414 102.066 315.28C107.918 307.207 113.132 297.387 113.714 296.277Z"
        fill="white"
        fill-opacity="0.5"
      />
      <path
        d="M119.677 285.68C119.927 285.874 120.26 285.985 120.593 285.985C121.536 286.013 122.701 285.291 124.365 283.655C127.055 281.019 129.468 278.217 131.715 275.055C137.539 266.927 142.698 257.078 143.28 255.969C143.474 255.636 143.391 255.358 143.308 255.192C143.197 254.998 142.975 254.859 142.725 254.859C142.42 254.859 142.226 255.026 142.143 255.109C141.228 255.941 133.074 263.514 126.75 271.227C124.309 274.223 122.229 277.274 120.371 280.575C119.4 282.462 118.374 284.681 119.677 285.68Z"
        fill="#3BB2F4"
      />
      <path
        d="M31.2298 219.766C31.5904 219.766 32.0064 219.766 32.4502 219.766C36.2222 219.628 39.8832 219.156 43.6552 218.324C53.4457 216.188 63.8464 212.332 65.0391 211.888C65.5106 211.721 65.6215 211.361 65.566 211.083C65.5106 210.806 65.3164 210.501 64.8172 210.501C64.8172 210.501 64.7617 210.501 64.734 210.501C63.5136 210.501 52.4195 210.667 42.4903 212.165C38.6629 212.748 35.085 213.635 31.5072 214.828C29.5103 215.494 27.2914 216.493 27.5965 218.102C27.9016 219.544 29.8154 219.738 31.2298 219.766Z"
        fill="white"
        fill-opacity="0.5"
      />
      <path
        d="M29.6212 175.88C29.5103 177.516 31.8678 178.238 33.8924 178.682C37.5812 179.458 41.2423 179.902 45.1252 180.041C45.458 180.041 45.8186 180.069 46.1791 180.069C55.6646 180.291 65.5106 179.264 67.4243 179.07C67.9235 179.014 68.1177 178.682 68.1454 178.404C68.1731 178.127 68.0067 177.766 67.5075 177.655C66.3149 177.378 55.4981 174.77 45.5412 173.827C43.8771 173.66 42.2407 173.577 40.6321 173.522C38.4965 173.466 36.4163 173.522 34.2252 173.716C32.1451 173.827 29.7321 174.243 29.6212 175.88Z"
        fill="#91E7F2"
      />
      <path
        d="M85.4244 174.603C85.8682 174.576 86.1178 174.27 86.1455 173.993C86.1733 173.688 86.0069 173.355 85.5631 173.216C84.6756 172.967 76.6878 170.609 69.2825 169.527C66.3981 169.111 63.7077 168.944 60.8233 169.027C59.1869 169.083 57.3009 169.305 57.1345 170.664C56.9681 172.023 58.7709 172.661 60.3518 173.105C63.0976 173.827 65.8156 174.298 68.7278 174.548C70.5029 174.687 72.4443 174.798 74.5245 174.825C79.8496 174.909 84.5092 174.659 85.4244 174.603Z"
        fill="#91E7F2"
      />
      <path
        d="M14.6442 183.204C14.5887 184.563 16.4192 185.09 18.0279 185.395C20.6627 185.894 23.2421 186.172 25.9879 186.227H26.4871C33.7815 186.311 41.6305 185.367 43.156 185.173C43.5997 185.118 43.8216 184.785 43.8216 184.507C43.8216 184.202 43.6552 183.869 43.1837 183.786C42.2684 183.592 34.142 181.816 26.6813 181.234C23.7968 181.012 21.0233 181.04 18.1943 181.317C16.5856 181.484 14.6996 181.844 14.6442 183.204Z"
        fill="#3BB2F4"
      />
      <path
        d="M36.1944 225.731C36.4995 225.62 36.7214 225.259 36.6659 224.954C36.6105 224.677 36.3886 224.399 35.9725 224.399C35.9448 224.399 35.8893 224.399 35.8616 224.399C34.6413 224.455 28.3454 224.815 22.6597 225.897C20.3854 226.341 18.2498 226.951 16.1419 227.756C15.3376 228.061 13.4238 228.81 13.7012 230.114C13.9508 231.196 15.3653 231.224 15.8368 231.224C16.1419 231.224 16.5024 231.224 16.9185 231.196C19.165 231.029 21.3561 230.641 23.6027 230.086C29.2052 228.588 35.0573 226.202 36.1944 225.731Z"
        fill="#3BB2F4"
      />
      <path
        d="M84.2872 199.571C84.8142 199.598 85.3966 199.626 85.9791 199.626C88.2256 199.682 90.4444 199.571 92.7465 199.293C99.9854 198.433 107.668 196.436 109.138 196.048C109.582 195.937 109.748 195.576 109.72 195.299C109.693 194.993 109.471 194.688 108.999 194.661C108.306 194.605 104.673 194.3 100.207 194.189C97.3228 194.133 94.6602 194.161 92.3027 194.3C89.4182 194.466 86.6725 194.855 83.8989 195.493C82.318 195.853 80.4875 196.464 80.5984 197.823C80.7648 199.182 82.6508 199.46 84.2872 199.571Z"
        fill="#007FC8"
      />
      <path
        d="M98.6818 249.006C99.3197 249.033 100.207 248.784 101.4 248.284C104.007 247.175 106.448 245.871 108.888 244.29C115.156 240.212 121.452 234.747 122.146 234.109C122.534 233.776 122.423 233.415 122.395 233.304C122.257 232.943 121.868 232.749 121.425 232.916C120.565 233.249 112.854 236.3 106.281 239.99C103.757 241.405 101.455 242.986 99.2642 244.789C97.9884 245.843 96.6571 247.175 97.3505 248.34C97.5446 248.617 97.9329 248.978 98.6818 249.006Z"
        fill="white"
        fill-opacity="0.5"
      />
      <path
        d="M118.956 272.891C119.677 272.919 120.593 272.447 121.924 271.365C124.115 269.59 126.112 267.648 128.026 265.456C132.935 259.797 137.456 252.806 137.983 252.03C138.205 251.697 138.121 251.392 138.038 251.281C137.927 251.059 137.678 250.92 137.456 250.92C137.206 250.92 137.012 251.059 136.929 251.114C135.736 252.03 129.468 256.884 124.337 262.072C122.312 264.125 120.537 266.289 118.929 268.619C118.013 269.978 117.07 271.643 118.069 272.558C118.291 272.752 118.623 272.891 118.956 272.891Z"
        fill="#007FC8"
      />
      <path
        d="M58.0498 205.535C58.5213 205.563 59.0483 205.591 59.5752 205.591C61.5722 205.646 63.5691 205.535 65.6492 205.286C72.3334 204.481 79.5446 202.595 80.3489 202.373C80.7926 202.262 80.9591 201.929 80.9313 201.624C80.9036 201.319 80.6817 201.013 80.2102 200.986C79.3504 200.902 76.1886 200.653 72.3057 200.569C69.7263 200.514 67.3688 200.542 65.2609 200.653C62.6538 200.791 60.1854 201.152 57.717 201.735C56.2747 202.068 54.6106 202.622 54.7216 203.898C54.8325 205.202 56.5521 205.452 58.0498 205.535Z"
        fill="#3BB2F4"
      />
      <path
        d="M50.0898 222.735C48.7031 223.317 47.1776 224.122 47.4827 225.37C47.7601 226.452 49.1468 226.48 49.6183 226.508C50.0066 226.508 50.4781 226.48 51.0328 226.424C53.5567 226.147 55.9974 225.675 58.4936 224.954C64.9559 223.068 71.751 220.044 72.5276 219.683C72.9436 219.489 73.0268 219.128 72.9714 218.879C72.9159 218.601 72.694 218.324 72.278 218.324C72.2503 218.324 72.1671 218.324 72.1393 218.324C71.3073 218.379 63.8742 218.99 57.3287 220.432C54.8048 220.987 52.4196 221.736 50.0898 222.735Z"
        fill="#91E7F2"
      />
      <path
        d="M52.5583 272.253C51.4211 273.224 50.2008 274.472 50.8942 275.554C51.0606 275.832 51.4211 276.165 52.1145 276.165C52.7247 276.165 53.5013 275.97 54.6107 275.471C56.9404 274.445 59.1038 273.224 61.2671 271.781C66.8142 268.036 72.389 263.043 73.0269 262.488C73.3874 262.155 73.3042 261.795 73.2487 261.684C73.1655 261.434 72.8882 261.24 72.6108 261.24C72.4999 261.24 72.389 261.267 72.2503 261.323C71.0022 261.85 64.4289 264.569 58.7987 267.87C56.5244 269.174 54.472 270.616 52.5583 272.253Z"
        fill="#91E7F2"
      />
      <path
        d="M74.1917 254.415C74.3582 254.72 74.7187 255.081 75.4953 255.109C76.0777 255.109 76.8543 254.942 77.8805 254.554C80.2658 253.666 82.5123 252.584 84.7589 251.253C90.5555 247.84 96.3799 243.18 97.0455 242.653C97.4338 242.348 97.3506 241.959 97.3229 241.848C97.2397 241.571 96.9623 241.377 96.685 241.377C96.6018 241.377 96.4908 241.404 96.3522 241.432C95.0764 241.876 88.3644 244.206 82.54 247.175C80.2103 248.34 78.1024 249.671 76.0777 251.197C74.8851 252.113 73.5816 253.305 74.1917 254.415Z"
        fill="#91E7F2"
      />
      <path
        d="M75.6893 287.261C75.8557 287.455 76.1608 287.677 76.66 287.705C77.2979 287.733 78.1577 287.372 79.3226 286.595C81.4582 285.208 83.4274 283.682 85.3134 281.879C90.0839 277.413 94.577 271.92 95.4368 270.838C95.7141 270.505 95.6032 270.172 95.5477 270.061C95.3813 269.701 94.9098 269.534 94.4938 269.784C93.8004 270.2 87.4491 274.112 82.2348 278.384C80.2379 280.048 78.4351 281.768 76.7987 283.71C75.8557 284.875 74.8295 286.318 75.6893 287.261Z"
        fill="#007FC8"
      />
      <path
        d="M110.164 314.336C110.331 314.447 110.525 314.503 110.747 314.503C111.329 314.503 112.161 314.198 114.13 311.729C116.377 308.927 118.429 306.014 120.371 302.851C125.419 294.695 130.106 285.153 130.633 284.043C130.772 283.765 130.772 283.516 130.633 283.294C130.522 283.1 130.3 282.961 130.078 282.961C129.718 282.961 129.523 283.211 129.44 283.294C128.691 284.209 121.896 292.337 116.488 300.327C114.408 303.406 112.577 306.43 110.941 309.648C109.721 311.978 109.138 313.671 110.164 314.336Z"
        fill="white"
        fill-opacity="0.5"
      />
      <path
        d="M99.1811 301.242C99.403 301.437 99.6803 301.548 99.9854 301.548C100.679 301.575 101.511 301.104 102.676 300.105C104.617 298.441 106.337 296.665 108.001 294.64C112.244 289.452 116.127 283.072 116.571 282.351C116.793 282.018 116.682 281.74 116.627 281.602C116.432 281.241 115.933 281.102 115.517 281.435C114.879 281.962 109.083 286.678 104.506 291.589C102.731 293.503 101.206 295.472 99.819 297.608C99.0424 298.857 98.2381 300.41 99.1811 301.242Z"
        fill="#007FC8"
      />
      <path
        d="M66.0098 288.759C70.919 284.431 75.5785 279.105 76.4938 278.051C76.7988 277.718 76.6879 277.385 76.6324 277.274C76.466 276.913 76.0222 276.747 75.6062 276.997C74.8851 277.413 68.4228 281.102 63.0699 285.208C61.0175 286.789 59.1592 288.482 57.4674 290.368C56.4689 291.478 55.415 292.865 56.2193 293.863C56.3857 294.058 56.6908 294.307 57.2455 294.335C57.8834 294.363 58.7155 294.03 59.8804 293.309C62.0437 291.949 64.0406 290.479 66.0098 288.759Z"
        fill="#3BB2F4"
      />
      <path
        d="M70.1701 237.16C70.5306 236.938 70.6139 236.605 70.5584 236.356C70.4752 236.051 70.1978 235.829 69.8927 235.829C69.8373 235.829 69.7818 235.829 69.6986 235.856C68.8943 235.995 61.5722 237.382 55.1931 239.546C52.7246 240.378 50.4504 241.377 48.2038 242.597C46.9003 243.319 45.458 244.29 45.9018 245.483C46.0405 245.899 46.4565 246.37 47.5659 246.398C48.0929 246.398 48.7308 246.343 49.5351 246.148C52.0313 245.621 54.3888 244.872 56.8017 243.874C62.9867 241.321 69.449 237.576 70.1701 237.16Z"
        fill="#91E7F2"
      />
      <path
        d="M88.3089 130.8C87.2272 130.772 86.728 131.244 86.5061 131.632C85.7018 133.075 87.5046 134.712 89.1687 135.988C92.1641 138.29 95.2705 140.288 98.7096 142.063C107.557 146.668 118.041 150.413 119.234 150.829C119.345 150.857 119.428 150.885 119.539 150.885C119.816 150.885 120.121 150.69 120.204 150.441C120.232 150.33 120.343 149.941 119.899 149.581C118.929 148.804 110.303 141.786 101.76 136.626C98.46 134.628 95.1318 132.992 91.6094 131.632C90.1949 131.077 89.1132 130.828 88.3089 130.8Z"
        fill="white"
        fill-opacity="0.5"
      />
      <path
        d="M66.1485 122.062C67.3133 123.033 68.4227 123.92 70.3919 124.919C74.5522 127.027 79.4336 128.525 80.4043 128.831C80.4598 128.858 80.5707 128.858 80.6262 128.858C80.9036 128.858 81.1809 128.692 81.2919 128.414C81.4028 128.137 81.2919 127.804 81.07 127.61C80.9036 127.443 76.6878 123.809 72.4721 121.202C70.697 120.12 69.3657 119.454 67.8958 118.955C67.2856 118.733 66.7864 118.65 66.3703 118.622C65.5106 118.594 65.1223 118.982 64.9281 119.315C64.2902 120.508 65.7047 121.673 66.1485 122.062Z"
        fill="#3BB2F4"
      />
      <path
        d="M59.6861 182.316C57.606 182.094 56.1638 182.094 54.5829 182.288C53.9727 182.371 52.1699 182.593 52.0867 183.925C52.0035 185.256 53.7785 185.7 54.361 185.839C55.8032 186.199 57.19 186.505 59.3533 186.56H59.4365C64.096 186.643 69.1716 185.866 70.1423 185.7C70.4474 185.645 70.7525 185.367 70.7802 185.062C70.808 184.729 70.5583 184.424 70.2255 184.341C69.9759 184.257 64.6507 182.815 59.6861 182.316Z"
        fill="#007FC8"
      />
      <path
        d="M73.11 190.666C74.2472 190.888 75.3566 191.082 76.882 191.11C77.298 191.11 77.7141 191.11 78.2133 191.11C83.0115 190.943 88.2534 189.806 88.8636 189.667C89.1687 189.612 89.4461 189.306 89.4461 188.973C89.4461 188.668 89.1687 188.363 88.8636 188.28C88.6417 188.224 83.2611 187.087 78.241 186.837C76.1332 186.726 74.6909 186.81 73.1378 187.087C72.5553 187.198 70.7248 187.531 70.7248 188.862C70.6971 190.222 72.4999 190.555 73.11 190.666Z"
        fill="white"
        fill-opacity="0.5"
      />
      <path
        d="M25.5719 210.473C26.3485 210.556 27.2638 210.612 28.2345 210.64C29.649 210.667 31.0635 210.584 32.6166 210.362C39.0235 209.502 45.7631 207.366 47.0667 206.922C47.4827 206.784 47.6214 206.451 47.5936 206.173C47.5659 205.896 47.344 205.591 46.928 205.535C46.9003 205.535 42.6568 205.064 37.6922 204.953C35.6675 204.897 33.7815 204.925 32.0897 205.008C29.233 205.147 27.2083 205.452 25.1281 206.035C24.3793 206.257 22.0773 206.895 22.2437 208.531C22.4101 210.196 24.7953 210.418 25.5719 210.473Z"
        fill="#007FC8"
      />
      <path
        d="M124.448 324.351C124.642 324.462 124.864 324.49 125.086 324.517C126.112 324.545 127.194 323.602 128.664 321.466C130.799 318.359 132.602 315.141 134.211 311.618C138.315 302.519 141.477 291.866 141.838 290.645C141.921 290.368 141.893 290.146 141.755 289.952C141.644 289.785 141.422 289.674 141.2 289.674C141.033 289.674 140.784 289.73 140.534 290.063C139.813 291.061 133.295 300.05 128.58 308.871C126.75 312.284 125.308 315.696 124.143 319.274C123.533 321.244 122.978 323.63 124.448 324.351Z"
        fill="#007FC8"
      />
      <path
        d="M121.286 327.236C121.175 327.069 120.953 326.958 120.759 326.958C120.537 326.958 120.288 327.097 120.149 327.264C119.677 327.874 117.292 331.12 115.517 334.393C114.796 335.697 114.241 337.001 113.77 338.388C113.576 338.971 113.104 340.358 114.075 340.829C114.213 340.885 114.352 340.94 114.491 340.94C115.046 340.94 115.545 340.552 116.21 339.609C117.042 338.416 117.764 337.195 118.374 335.836C119.982 332.368 121.231 328.318 121.369 327.874C121.452 327.68 121.425 327.43 121.286 327.236Z"
        fill="#3BB2F4"
      />
      <path
        d="M112.91 346.156C112.688 346.156 112.466 346.294 112.328 346.461C112.05 346.822 109.471 350.206 107.585 353.507C106.836 354.811 106.254 356.087 105.782 357.474C105.588 358.057 105.089 359.416 106.032 359.915C106.17 359.999 106.309 360.026 106.475 360.026C107.002 360.026 107.529 359.666 108.195 358.75C109.055 357.585 109.776 356.365 110.442 355.005C112.05 351.676 113.326 347.848 113.576 347.099C113.631 346.905 113.603 346.627 113.492 346.461C113.326 346.267 113.132 346.156 112.91 346.156Z"
        fill="#3BB2F4"
      />
      <path
        d="M110.719 335.974C114.186 329.317 116.959 321.494 117.265 320.606C117.375 320.273 117.292 320.051 117.209 319.912C117.098 319.746 116.876 319.607 116.654 319.607C116.488 319.607 116.266 319.663 116.044 319.94C115.462 320.661 110.22 327.125 106.337 333.533C104.839 336.002 103.619 338.499 102.593 341.162C102.01 342.688 101.483 344.519 102.676 345.185C102.87 345.296 103.064 345.351 103.286 345.351C104.09 345.379 104.978 344.685 106.17 343.159C107.89 340.885 109.388 338.554 110.719 335.974Z"
        fill="white"
        fill-opacity="0.5"
      />
      <path
        d="M138.343 320.883C138.509 320.939 138.704 320.994 138.87 320.994C139.924 321.022 140.978 319.995 142.337 317.693C144.223 314.419 145.804 311.063 147.107 307.456C150.519 298.08 152.849 287.205 153.126 285.957C153.181 285.68 153.154 285.458 152.987 285.291C152.849 285.125 152.654 285.042 152.46 285.042C152.266 285.042 151.989 285.097 151.767 285.458C151.129 286.512 145.305 295.972 141.283 305.126C139.73 308.677 138.537 312.172 137.65 315.834C137.178 317.859 136.818 320.273 138.343 320.883Z"
        fill="#3BB2F4"
      />
      <path
        d="M153.126 303.712C152.987 303.545 152.821 303.462 152.599 303.462C152.349 303.462 152.072 303.628 151.961 303.85C151.517 304.599 149.271 308.594 147.69 312.533C147.052 314.087 146.608 315.64 146.248 317.277C146.109 317.943 145.776 319.496 146.858 319.885C146.969 319.913 147.08 319.94 147.191 319.94C147.995 319.968 148.55 319.108 148.993 318.248C149.77 316.805 150.408 315.307 150.935 313.698C152.238 309.676 153.071 305.182 153.237 304.294C153.292 304.1 153.265 303.85 153.126 303.712Z"
        fill="#007FC8"
      />
      <path
        d="M133.517 352.758C133.268 352.758 132.99 352.925 132.879 353.146C132.602 353.618 130.244 357.779 128.608 361.829C127.97 363.383 127.526 364.937 127.166 366.573C127.027 367.239 126.694 368.793 127.776 369.181C127.887 369.209 127.998 369.236 128.109 369.236C128.913 369.264 129.468 368.404 129.912 367.544C130.688 366.102 131.326 364.604 131.853 362.995C133.157 358.972 133.989 354.478 134.155 353.59C134.183 353.396 134.127 353.146 134.017 352.98C133.906 352.869 133.711 352.758 133.517 352.758Z"
        fill="#91E7F2"
      />
      <path
        d="M148.383 346.627C148.106 346.627 147.829 346.821 147.718 347.071C147.496 347.57 145.582 351.981 144.417 356.17C143.973 357.807 143.668 359.388 143.502 361.025C143.446 361.691 143.28 363.3 144.389 363.549C144.473 363.577 144.528 363.577 144.611 363.577C145.471 363.605 145.97 362.634 146.331 361.718C146.941 360.193 147.413 358.639 147.773 356.975C148.661 352.73 148.993 347.931 149.021 347.376C149.049 347.182 148.966 346.96 148.855 346.821C148.744 346.71 148.577 346.627 148.383 346.627Z"
        fill="#3BB2F4"
      />
      <path
        d="M168.325 336.224C168.824 331.924 168.713 327.097 168.685 326.57C168.685 326.21 168.38 325.877 168.02 325.849C167.715 325.849 167.437 326.071 167.326 326.376C167.16 326.875 165.662 331.453 164.886 335.725C164.581 337.389 164.442 338.998 164.442 340.635C164.442 341.329 164.442 342.91 165.551 343.076C165.579 343.076 165.662 343.076 165.69 343.076C166.605 343.104 167.049 341.994 167.326 341.051C167.798 339.47 168.131 337.889 168.325 336.224Z"
        fill="white"
        fill-opacity="0.5"
      />
      <path
        d="M159.755 359.638C159.533 359.638 159.2 359.749 159.061 360.137C158.756 360.969 157.203 365.269 156.343 369.375C155.983 371.012 155.789 372.621 155.733 374.258C155.705 374.923 155.65 376.532 156.759 376.727C156.787 376.727 156.898 376.754 156.926 376.754C157.813 376.782 158.257 375.783 158.59 374.785C159.117 373.231 159.477 371.65 159.727 369.985C160.365 365.713 160.393 360.886 160.393 360.359C160.42 359.999 160.115 359.666 159.755 359.638Z"
        fill="#007FC8"
      />
      <path
        d="M246.621 310.036C244.708 306.291 242.101 302.518 241.574 301.769C241.463 301.603 241.213 301.464 240.991 301.437C240.769 301.437 240.575 301.52 240.436 301.714C240.353 301.825 240.242 302.047 240.325 302.38C240.547 303.24 241.795 307.651 243.46 311.507C244.125 313.06 244.902 314.475 245.817 315.862C246.51 316.916 247.065 317.36 247.62 317.36C247.786 317.36 247.925 317.332 248.091 317.249C249.118 316.777 248.646 315.252 248.452 314.586C247.98 313.06 247.37 311.562 246.621 310.036Z"
        fill="#3BB2F4"
      />
      <path
        d="M263.429 288.287C264.455 289.647 265.509 290.84 266.73 291.977C267.506 292.698 268.089 293.031 268.588 293.059C268.837 293.059 269.087 292.976 269.281 292.837C270.169 292.116 269.364 290.756 269.004 290.174C268.172 288.759 267.229 287.427 266.119 286.151C263.401 282.961 259.962 279.909 259.296 279.299C259.186 279.188 258.991 279.105 258.825 279.105C258.575 279.105 258.353 279.216 258.215 279.438C258.104 279.632 258.104 279.965 258.215 280.159C258.464 280.603 260.794 284.792 263.429 288.287Z"
        fill="#007FC8"
      />
      <path
        d="M199.777 348.847C199.721 348.542 199.416 348.292 199.111 348.264C198.75 348.264 198.445 348.569 198.418 348.902C198.362 349.429 197.807 354.173 197.918 358.556C197.974 360.248 198.14 361.857 198.473 363.466C198.612 364.104 198.917 365.602 199.971 365.63C199.999 365.63 200.026 365.63 200.054 365.63C201.191 365.575 201.33 363.966 201.385 363.3C201.524 361.663 201.524 360.054 201.358 358.362C200.997 354.173 199.971 349.707 199.777 348.847Z"
        fill="#3BB2F4"
      />
      <path
        d="M191.817 365.713C191.761 365.38 191.484 365.103 191.151 365.103C190.818 365.103 190.513 365.38 190.458 365.685C190.374 366.129 189.653 370.096 189.515 373.786C189.459 375.201 189.515 376.588 189.709 377.947C189.792 378.53 189.986 379.917 191.012 379.944C191.012 379.944 191.012 379.944 191.04 379.944C192.094 379.944 192.288 378.557 192.399 377.975C192.621 376.588 192.704 375.228 192.649 373.814C192.538 370.29 191.928 366.462 191.817 365.713Z"
        fill="#91E7F2"
      />
      <path
        d="M208.707 306.791C209.068 307.734 209.512 308.677 210.371 308.705C210.455 308.705 210.538 308.705 210.621 308.677C211.73 308.427 211.62 306.846 211.592 306.18C211.481 304.544 211.231 302.935 210.815 301.298C209.761 297.054 207.986 292.615 207.792 292.116C207.681 291.866 207.431 291.644 207.154 291.644C206.96 291.644 206.794 291.727 206.655 291.838C206.544 291.949 206.433 292.116 206.461 292.421C206.488 293.308 206.71 297.886 207.459 302.019C207.709 303.684 208.125 305.237 208.707 306.791Z"
        fill="white"
        fill-opacity="0.5"
      />
      <path
        d="M218.331 372.011C217.721 368.404 216.529 364.548 216.39 364.132C216.307 363.855 216.029 363.633 215.752 363.605C215.392 363.605 215.059 363.938 215.059 364.326C215.031 365.075 214.892 368.904 215.253 372.427C215.392 373.842 215.641 375.173 216.029 376.505C216.168 377.032 216.529 378.28 217.472 378.308C217.499 378.308 217.583 378.308 217.61 378.308C218.637 378.169 218.664 376.782 218.664 376.172C218.664 374.757 218.581 373.398 218.331 372.011Z"
        fill="#007FC8"
      />
      <path
        d="M226.07 291.256C225.959 291.034 225.709 290.867 225.459 290.867C225.265 290.867 225.071 290.95 224.933 291.117C224.794 291.256 224.738 291.533 224.766 291.727C224.849 292.254 225.626 296.97 226.929 301.159C227.429 302.768 228.039 304.266 228.788 305.737C229.231 306.569 229.758 307.429 230.535 307.429C230.646 307.429 230.785 307.401 230.895 307.373C231.977 307.013 231.672 305.431 231.533 304.766C231.228 303.157 230.785 301.603 230.174 300.022C228.704 296.055 226.486 292.032 226.07 291.256Z"
        fill="#91E7F2"
      />
      <path
        d="M227.595 260.99C227.456 261.184 227.456 261.489 227.54 261.684C227.734 262.183 229.564 266.649 231.728 270.394C232.587 271.865 233.503 273.168 234.557 274.445C235.305 275.332 235.86 275.721 236.415 275.748C236.609 275.748 236.831 275.693 236.997 275.582C237.968 274.972 237.33 273.529 237.053 272.891C236.387 271.393 235.611 269.978 234.668 268.563C232.227 264.929 229.037 261.351 228.704 260.962C228.566 260.824 228.372 260.713 228.177 260.713C227.956 260.685 227.734 260.796 227.595 260.99Z"
        fill="#91E7F2"
      />
      <path
        d="M253.056 254.998C254.193 256.246 255.386 257.328 256.717 258.327C257.494 258.937 258.076 259.187 258.548 259.214C258.936 259.214 259.185 259.048 259.324 258.909C260.128 258.105 259.185 256.829 258.797 256.274C257.826 254.942 256.745 253.722 255.524 252.557C252.39 249.56 248.48 246.731 248.064 246.426C247.953 246.342 247.786 246.287 247.648 246.259C247.398 246.259 247.148 246.398 247.037 246.62C246.927 246.842 246.954 247.175 247.093 247.369C247.564 248.118 250.172 251.863 253.056 254.998Z"
        fill="#3BB2F4"
      />
      <path
        d="M255.108 352.564C253.444 348.514 251.031 344.38 250.754 343.936C250.643 343.742 250.393 343.576 250.144 343.548C249.95 343.548 249.728 343.631 249.617 343.798C249.478 343.964 249.423 344.214 249.478 344.408C249.589 344.935 250.477 349.651 251.863 353.785C252.39 355.394 253.056 356.864 253.86 358.306C254.498 359.444 255.025 359.943 255.635 359.971C255.774 359.971 255.885 359.943 256.024 359.915C257.078 359.527 256.745 357.974 256.606 357.308C256.246 355.671 255.746 354.118 255.108 352.564Z"
        fill="white"
        fill-opacity="0.5"
      />
      <path
        d="M286.976 331.175C284.508 327.791 281.318 324.462 280.68 323.824C280.569 323.685 280.348 323.602 280.181 323.602C279.959 323.602 279.71 323.713 279.599 323.907C279.543 324.018 279.405 324.268 279.571 324.628C279.932 325.433 281.845 329.594 284.12 333.145C285.035 334.56 286.006 335.864 287.115 337.084C287.892 337.917 288.446 338.277 288.973 338.305C289.195 338.305 289.417 338.249 289.583 338.111C290.526 337.473 289.833 336.03 289.528 335.42C288.835 333.922 287.975 332.535 286.976 331.175Z"
        fill="white"
        fill-opacity="0.5"
      />
      <path
        d="M189.487 287.622C189.515 287.622 189.515 287.622 189.487 287.622C190.652 287.622 190.874 286.068 190.985 285.375C191.234 283.738 191.345 282.129 191.29 280.437C191.179 276.081 190.402 271.338 190.319 270.811C190.263 270.478 189.986 270.2 189.653 270.2C189.32 270.2 189.015 270.478 188.96 270.811C188.932 271.005 188.017 275.943 187.85 280.437C187.795 282.129 187.85 283.738 188.1 285.375C188.156 286.013 188.377 287.594 189.487 287.622Z"
        fill="#007FC8"
      />
      <path
        d="M156.482 262.876C156.371 262.71 156.149 262.599 155.955 262.599C155.816 262.599 155.539 262.627 155.317 262.987C154.846 263.709 150.713 270.117 147.718 276.275C146.553 278.661 145.61 281.047 144.861 283.516C144.389 285.069 144.057 286.734 145.138 287.205C145.277 287.261 145.416 287.289 145.582 287.316C146.331 287.344 147.08 286.623 148.106 284.986C149.465 282.767 150.63 280.492 151.656 278.051C154.319 271.67 156.371 264.347 156.621 263.542C156.648 263.237 156.565 263.015 156.482 262.876Z"
        fill="white"
        fill-opacity="0.5"
      />
      <path
        d="M131.742 324.046C131.631 323.879 131.409 323.768 131.215 323.768C131.077 323.768 130.799 323.796 130.577 324.129C130.05 324.906 125.391 331.786 122.09 338.499C120.814 341.107 119.816 343.687 119.012 346.405C118.568 347.986 118.207 349.845 119.455 350.428C119.622 350.511 119.788 350.539 119.955 350.539C120.787 350.566 121.647 349.79 122.756 348.097C124.281 345.712 125.557 343.243 126.667 340.552C129.523 333.616 131.631 325.572 131.853 324.656C131.936 324.379 131.825 324.157 131.742 324.046Z"
        fill="#91E7F2"
      />
      <path
        d="M154.984 282.517C155.123 282.573 155.262 282.6 155.428 282.6C156.371 282.628 157.065 281.657 157.508 280.908C158.285 279.632 159.006 278.384 159.699 276.275C161.197 271.837 161.946 266.788 162.085 265.817C162.112 265.623 162.057 265.373 161.946 265.234C161.807 265.096 161.641 264.985 161.447 264.985C161.225 264.985 160.947 265.151 160.809 265.345C160.504 265.845 157.647 270.339 155.733 274.777C154.901 276.691 154.457 278.106 154.18 279.66C154.069 280.242 153.736 282.046 154.984 282.517Z"
        fill="#007FC8"
      />
      <path
        d="M138.094 326.321C137.844 326.321 137.594 326.487 137.456 326.681C137.428 326.737 134.349 331.897 132.38 336.945C131.576 338.998 131.132 340.524 130.882 342.189C130.799 342.827 130.494 344.741 131.825 345.185C131.964 345.24 132.103 345.268 132.241 345.268C133.24 345.296 133.961 344.214 134.405 343.409C135.209 341.994 135.902 340.663 136.596 338.416C138.066 333.506 138.704 327.791 138.787 327.153C138.815 326.959 138.759 326.709 138.621 326.57C138.482 326.431 138.288 326.348 138.094 326.321Z"
        fill="white"
        fill-opacity="0.5"
      />
      <path
        d="M156.288 303.878C156.426 303.933 156.565 303.961 156.732 303.961C157.813 303.989 158.839 302.907 160.06 300.41C161.724 297.026 163.055 293.586 164.137 289.869C166.91 280.27 168.491 269.257 168.685 268.009C168.713 267.759 168.685 267.537 168.519 267.371C168.408 267.232 168.214 267.121 168.02 267.121C167.853 267.121 167.548 267.176 167.326 267.592C166.744 268.674 161.585 278.55 158.201 287.927C156.898 291.561 155.955 295.139 155.317 298.857C154.929 300.937 154.735 303.379 156.288 303.878Z"
        fill="#91E7F2"
      />
      <path
        d="M149.021 323.796C149.049 323.546 148.994 323.352 148.855 323.186C148.716 323.047 148.55 322.964 148.356 322.936C148.217 322.936 147.884 322.964 147.662 323.408C147.246 324.24 143.557 331.702 141.2 338.804C140.285 341.55 139.647 344.269 139.231 347.071C138.981 348.68 138.898 350.594 140.201 350.982C140.312 351.01 140.423 351.038 140.534 351.038C141.45 351.066 142.226 350.206 143.141 348.236C144.334 345.656 145.277 343.048 145.998 340.247C147.94 333.006 148.938 324.739 149.021 323.796Z"
        fill="#007FC8"
      />
      <path
        d="M155.678 325.655C156.482 324.074 157.203 322.576 157.841 320.079C159.172 314.78 159.644 308.788 159.699 307.651C159.727 307.457 159.644 307.207 159.533 307.068C159.394 306.929 159.228 306.846 159.034 306.846C158.784 306.846 158.507 307.013 158.368 307.262C158.229 307.512 155.151 313.227 153.265 318.747C152.488 321.05 152.072 322.714 151.878 324.545C151.795 325.211 151.573 327.292 152.987 327.708C153.098 327.735 153.237 327.763 153.348 327.763C154.457 327.763 155.206 326.57 155.678 325.655Z"
        fill="white"
        fill-opacity="0.5"
      />
      <path
        d="M162.14 319.663C162.001 319.663 161.613 319.718 161.419 320.217C160.975 321.383 156.926 331.758 154.624 341.44C153.736 345.212 153.181 348.846 152.96 352.619C152.849 354.7 152.904 357.169 154.513 357.474C154.596 357.502 154.679 357.502 154.79 357.502C155.927 357.529 156.898 356.337 157.869 353.618C159.145 350.067 160.087 346.488 160.753 342.688C162.445 332.84 162.806 321.715 162.833 320.467C162.833 320.384 162.861 320.106 162.639 319.885C162.5 319.746 162.306 319.663 162.14 319.663Z"
        fill="#91E7F2"
      />
      <path
        d="M167.271 307.318C168.242 304.655 168.935 301.964 169.434 299.107C170.71 291.727 170.96 283.405 170.988 282.462C170.988 282.24 170.932 282.046 170.793 281.907C170.682 281.768 170.488 281.685 170.322 281.685C170.211 281.685 169.823 281.713 169.629 282.212C169.296 283.072 166.273 290.812 164.525 298.108C163.86 300.938 163.444 303.684 163.277 306.514C163.194 308.15 163.249 310.064 164.581 310.342C164.664 310.37 164.747 310.37 164.803 310.37C166.023 310.37 166.772 308.761 167.271 307.318Z"
        fill="white"
        fill-opacity="0.5"
      />
      <path
        d="M174.149 287.622C174.205 287.622 174.26 287.622 174.316 287.622C175.398 287.649 175.952 286.429 176.257 285.486C176.757 284.043 177.173 282.684 177.394 280.492C177.894 275.721 177.478 270.367 177.422 269.756C177.394 269.396 177.089 269.063 176.729 269.063C176.451 269.063 176.146 269.285 176.035 269.562C175.952 269.784 174.066 274.999 173.151 279.882C172.763 281.935 172.652 283.405 172.707 284.986C172.763 285.597 172.846 287.427 174.149 287.622Z"
        fill="#3BB2F4"
      />
      <path
        d="M204.186 285.652C204.547 286.484 205.129 287.594 206.128 287.622C206.239 287.622 206.35 287.622 206.433 287.594C207.737 287.289 207.653 285.458 207.626 284.848C207.57 283.35 207.459 281.907 206.905 279.771C205.684 275.249 203.521 270.616 203.077 269.729C202.966 269.507 202.689 269.312 202.439 269.285C202.245 269.285 202.079 269.368 201.94 269.479C201.801 269.618 201.718 269.839 201.718 270.006C201.718 270.061 201.829 275.721 202.716 280.686C203.16 282.795 203.576 284.209 204.186 285.652Z"
        fill="#91E7F2"
      />
      <path
        d="M222.464 283.627C223.712 283.183 223.435 281.38 223.352 280.77C223.13 279.271 222.852 277.857 222.048 275.804C220.273 271.31 217.444 266.76 217.139 266.261C217.028 266.067 216.778 265.9 216.529 265.9C216.335 265.9 216.14 265.983 216.002 266.122C215.863 266.261 215.808 266.51 215.835 266.705C215.863 266.927 216.612 272.447 218.054 277.191C218.664 279.188 219.219 280.548 219.996 281.907C220.412 282.656 221.077 283.627 222.02 283.655C222.159 283.682 222.325 283.655 222.464 283.627Z"
        fill="white"
        fill-opacity="0.5"
      />
      <path
        d="M243.016 264.652C244.208 266.344 245.179 267.481 246.344 268.536C247.148 269.257 247.786 269.617 248.369 269.617C248.674 269.617 248.979 269.534 249.201 269.34C250.255 268.536 249.423 266.899 249.145 266.344C248.452 264.985 247.758 263.736 246.344 262.044C243.349 258.493 239.438 255.164 238.661 254.526C238.55 254.415 238.356 254.332 238.19 254.332C237.968 254.332 237.718 254.443 237.607 254.637C237.469 254.831 237.469 255.137 237.58 255.331C237.635 255.442 240.076 260.491 243.016 264.652Z"
        fill="#007FC8"
      />
      <path
        d="M281.235 284.487C282.483 285.402 283.398 285.846 284.119 285.874C284.646 285.874 284.979 285.652 285.146 285.485C286.116 284.514 285.09 282.905 284.119 281.602C282.705 279.743 281.346 278.023 278.85 275.72C273.358 270.644 266.535 265.9 265.759 265.373C265.37 265.095 264.871 265.206 264.705 265.567C264.649 265.678 264.538 265.983 264.788 266.344C265.01 266.621 270.03 273.501 275.41 279.188C277.602 281.518 279.293 283.072 281.235 284.487Z"
        fill="#3BB2F4"
      />
      <path
        d="M257.743 334.31C255.691 330.121 252.668 325.988 252.085 325.183C251.974 325.017 251.725 324.878 251.503 324.878C251.309 324.878 251.087 324.961 250.948 325.128C250.809 325.294 250.754 325.544 250.809 325.738C250.809 325.793 252.002 331.342 253.833 336.03C254.581 337.972 255.275 339.276 256.162 340.579C256.883 341.634 257.549 342.161 258.215 342.161C258.409 342.161 258.603 342.133 258.769 342.05C259.99 341.523 259.574 339.72 259.435 339.137C259.102 337.694 258.714 336.307 257.743 334.31Z"
        fill="#91E7F2"
      />
      <path
        d="M272.082 355.726C270.64 352.98 268.532 350.233 268.116 349.706C267.977 349.54 267.783 349.429 267.561 349.429C267.34 349.429 267.145 349.512 267.007 349.679C266.868 349.845 266.84 350.095 266.868 350.289C266.868 350.317 267.756 353.979 269.087 357.141C269.642 358.445 270.113 359.305 270.751 360.193C271.306 360.942 271.777 361.302 272.304 361.302C272.471 361.302 272.637 361.274 272.803 361.191C273.774 360.72 273.414 359.416 273.303 358.972C273.053 357.973 272.776 357.058 272.082 355.726Z"
        fill="white"
        fill-opacity="0.5"
      />
      <path
        d="M170.377 343.492C170.1 343.492 169.795 343.714 169.684 343.992C169.656 344.047 167.742 349.29 166.799 354.312C166.411 356.364 166.3 357.835 166.356 359.416C166.383 360.026 166.439 361.857 167.77 362.051C167.826 362.051 167.881 362.051 167.937 362.051C169.018 362.079 169.573 360.859 169.878 359.915C170.377 358.473 170.793 357.113 171.015 354.922C171.487 350.261 171.126 345.157 171.043 344.186C171.015 343.825 170.71 343.492 170.377 343.492Z"
        fill="#3BB2F4"
      />
      <path
        d="M185.077 292.975C185.41 282.989 184.273 271.92 184.134 270.671C184.079 270.117 183.662 269.95 183.441 269.95C183.357 269.95 182.858 269.978 182.719 270.588C182.414 271.781 179.835 282.6 178.837 292.559C178.448 296.415 178.421 300.105 178.698 303.878C178.864 305.958 179.253 308.372 180.917 308.483H180.972C182.581 308.511 183.274 306.208 183.69 304.211C184.495 300.493 184.966 296.832 185.077 292.975Z"
        fill="white"
        fill-opacity="0.5"
      />
      <path
        d="M194.063 307.734C193.952 307.124 193.453 307.068 193.37 307.068C193.148 307.068 192.732 307.207 192.649 307.762C192.454 308.983 190.763 319.968 190.624 329.955C190.569 333.811 190.818 337.501 191.428 341.246C191.761 343.271 192.344 345.629 193.952 345.656H194.008C195.644 345.629 196.171 343.243 196.448 341.162C196.92 337.417 197.059 333.728 196.864 329.872C196.365 319.857 194.285 308.955 194.063 307.734Z"
        fill="#00B1FF"
      />
      <path
        d="M216.335 301.27C217.472 303.767 218.442 304.904 219.524 304.932C219.663 304.932 219.829 304.904 219.968 304.877C221.549 304.433 221.438 301.991 221.16 299.911C220.661 296.166 219.829 292.559 218.664 288.87C215.613 279.382 210.815 269.34 210.261 268.203C210.066 267.786 209.761 267.731 209.595 267.731C209.401 267.731 209.207 267.814 209.068 267.953C208.929 268.119 208.846 268.341 208.902 268.619C209.04 269.839 210.233 280.908 212.646 290.59C213.589 294.335 214.809 297.83 216.335 301.27Z"
        fill="#007FC8"
      />
      <path
        d="M238.301 333.506C235.056 324.046 230.036 314.142 229.481 313.005C229.287 312.616 228.982 312.533 228.816 312.533C228.621 312.533 228.427 312.616 228.289 312.755C228.15 312.922 228.094 313.144 228.122 313.449C228.372 315.446 229.759 325.932 232.338 335.337C233.364 339.054 234.64 342.549 236.249 345.961C237.413 348.43 238.412 349.512 239.466 349.54C239.632 349.54 239.799 349.512 239.937 349.485C241.518 349.013 241.352 346.572 241.019 344.491C240.464 340.746 239.577 337.167 238.301 333.506Z"
        fill="#007FC8"
      />
      <path
        d="M269.17 311.368C270.723 313.171 271.861 313.976 272.804 314.003C273.109 314.003 273.414 313.92 273.663 313.754C275.05 312.866 274.246 310.563 273.358 308.649C271.805 305.209 269.975 301.991 267.783 298.801C262.125 290.562 254.609 282.351 253.777 281.435C253.722 281.352 253.528 281.158 253.195 281.158C252.973 281.158 252.723 281.269 252.612 281.463C252.501 281.602 252.418 281.851 252.585 282.24C253.056 283.377 257.41 293.641 262.514 302.186C264.538 305.515 266.702 308.511 269.17 311.368Z"
        fill="#91E7F2"
      />
      <path
        d="M268.865 330.01C263.762 321.41 256.856 312.727 256.051 311.729C255.719 311.312 255.136 311.312 254.887 311.673C254.776 311.812 254.692 312.061 254.831 312.422C255.219 313.587 258.853 324.101 263.401 333.006C265.149 336.446 267.118 339.581 269.392 342.605C270.89 344.574 271.971 345.462 272.97 345.49C273.247 345.49 273.497 345.434 273.746 345.296C275.189 344.491 274.523 342.133 273.802 340.191C272.443 336.668 270.834 333.311 268.865 330.01Z"
        fill="#3BB2F4"
      />
      <path
        d="M237.801 298.857C238.023 298.857 238.245 298.829 238.439 298.746C239.965 298.08 239.521 295.694 238.966 293.669C237.996 290.035 236.692 286.567 235.056 283.072C230.784 274.028 224.71 264.707 224.017 263.681C223.823 263.376 223.573 263.292 223.379 263.292C223.157 263.292 222.963 263.376 222.824 263.542C222.686 263.736 222.63 263.958 222.714 264.263C223.185 266.233 225.709 276.497 229.287 285.569C230.701 289.175 232.365 292.476 234.335 295.694C235.721 297.858 236.803 298.857 237.801 298.857Z"
        fill="#3BB2F4"
      />
      <path
        d="M262.652 278.384C264.289 279.854 265.454 280.548 266.369 280.575C266.757 280.575 267.118 280.464 267.423 280.215C268.699 279.161 267.617 276.969 266.535 275.194C264.566 271.976 262.375 268.98 259.823 266.094C253.195 258.577 244.791 251.336 243.848 250.504C243.793 250.448 243.598 250.282 243.321 250.282C243.071 250.282 242.85 250.421 242.711 250.615C242.628 250.754 242.517 251.031 242.766 251.419C243.376 252.501 248.896 262.155 254.998 270.061C257.355 273.141 259.851 275.859 262.652 278.384Z"
        fill="#91E7F2"
      />
      <path
        d="M285.867 260.213C286.865 258.909 285.312 257.023 283.842 255.525C281.207 252.834 278.406 250.421 275.244 248.174C267.118 242.376 257.272 237.188 256.162 236.605C255.719 236.383 255.275 236.522 255.108 236.911C255.053 237.049 254.942 237.382 255.303 237.771C256.135 238.686 263.679 246.814 271.417 253.167C274.412 255.608 277.463 257.689 280.763 259.547C282.344 260.435 283.509 260.879 284.369 260.879C285.229 260.851 285.645 260.518 285.867 260.213Z"
        fill="#3BB2F4"
      />
      <path
        d="M218.526 336.252C216.39 326.487 212.535 316.056 212.091 314.863C211.925 314.392 211.564 314.336 211.398 314.336C211.204 314.336 211.037 314.419 210.899 314.53C210.677 314.752 210.677 315.03 210.677 315.141C210.677 316.361 210.843 327.458 212.341 337.389C212.923 341.217 213.811 344.796 215.003 348.375C215.919 351.093 216.834 352.286 217.971 352.314C218.082 352.314 218.165 352.314 218.276 352.286C219.885 351.981 220.023 349.54 219.94 347.459C219.829 343.686 219.358 340.025 218.526 336.252Z"
        fill="white"
        fill-opacity="0.5"
      />
      <path
        d="M178.559 311.756C178.476 311.756 177.977 311.784 177.838 312.422C177.561 313.615 174.954 324.434 174.011 334.393C173.65 338.249 173.595 341.939 173.9 345.712C174.066 347.792 174.482 350.206 176.119 350.317H176.174C177.783 350.345 178.476 348.042 178.892 346.017C179.669 342.327 180.112 338.638 180.251 334.782C180.584 324.823 179.419 313.754 179.28 312.478C179.197 311.923 178.781 311.784 178.559 311.756Z"
        fill="#91E7F2"
      />
      <path
        d="M174.843 294.501C174.815 294.002 174.482 293.78 174.149 293.78C173.9 293.78 173.567 293.919 173.428 294.363C173.179 295.25 170.821 303.24 169.739 310.647C169.323 313.532 169.157 316.278 169.24 319.108C169.296 320.745 169.518 322.631 170.877 322.797C170.904 322.797 170.987 322.825 170.987 322.797C172.263 322.825 172.901 321.077 173.289 319.579C174.011 316.833 174.482 314.114 174.732 311.202C175.314 303.933 174.926 296.027 174.843 294.501Z"
        fill="#007FC8"
      />
      <path
        d="M184.716 336.085C184.439 336.085 184.106 336.224 183.995 336.724C183.801 337.639 182.026 345.767 181.444 353.23C181.222 356.115 181.25 358.889 181.527 361.718C181.693 363.355 182.054 365.214 183.413 365.297H183.441C184.772 365.325 185.299 363.494 185.604 361.913C186.131 359.138 186.408 356.364 186.436 353.479C186.519 346.183 185.576 338.332 185.382 336.807C185.354 336.307 185.022 336.113 184.716 336.085Z"
        fill="#007FC8"
      />
      <path
        d="M230.258 356.337C228.788 350.705 226.402 344.852 225.931 343.714C225.765 343.27 225.404 343.243 225.293 343.243C225.099 343.243 224.905 343.326 224.794 343.465C224.683 343.576 224.572 343.742 224.6 344.047C224.655 345.24 225.016 351.565 226.097 357.252C226.541 359.527 227.151 361.663 227.956 363.771C228.427 364.964 229.01 366.212 230.036 366.24C230.119 366.24 230.23 366.24 230.313 366.212C231.617 365.907 231.478 363.882 231.395 363.022C231.201 360.775 230.84 358.584 230.258 356.337Z"
        fill="#3BB2F4"
      />
      <path
        d="M199.499 287.15C198.64 279.91 196.643 272.225 196.254 270.755C196.143 270.339 195.838 270.172 195.589 270.172C195.256 270.172 194.895 270.394 194.868 270.894C194.784 271.809 194.091 280.131 194.507 287.594C194.673 290.479 195.062 293.225 195.7 295.999C196.06 297.525 196.615 299.273 197.891 299.301C197.946 299.301 197.974 299.301 198.029 299.301C199.388 299.19 199.666 297.303 199.777 295.667C199.943 292.809 199.832 290.035 199.499 287.15Z"
        fill="#3BB2F4"
      />
      <path
        d="M244.985 280.631C246.178 282.073 247.037 282.711 247.814 282.739C248.064 282.739 248.313 282.684 248.535 282.545C249.7 281.824 249.118 280.02 248.48 278.522C247.37 275.915 246.067 273.474 244.486 271.032C240.409 264.763 234.945 258.465 234.307 257.772C233.974 257.384 233.419 257.411 233.17 257.772C233.059 257.911 232.976 258.16 233.114 258.493C233.447 259.353 236.498 267.065 240.187 273.64C241.601 276.137 243.182 278.439 244.985 280.631Z"
        fill="white"
        fill-opacity="0.5"
      />
      <path
        d="M262.264 255.58C264.317 257.605 266.48 259.381 268.81 260.99C270.058 261.85 270.973 262.266 271.666 262.266C272.221 262.266 272.554 262.044 272.748 261.85C273.663 260.851 272.609 259.27 271.583 257.994C269.808 255.802 267.867 253.805 265.676 251.891C260.018 246.98 253.028 242.459 252.252 241.932C251.863 241.682 251.392 241.793 251.198 242.153C251.142 242.264 251.031 242.597 251.309 242.986C252.224 244.206 257.078 250.448 262.264 255.58Z"
        fill="#91E7F2"
      />
      <path
        d="M205.518 314.253C204.713 307.568 202.827 300.355 202.606 299.55C202.495 299.134 202.19 298.968 201.94 298.968C201.607 298.968 201.274 299.19 201.219 299.689C201.108 301.048 200.525 308.122 200.886 314.642C201.025 317.249 201.385 319.718 201.968 322.187C202.3 323.574 202.827 325.156 203.992 325.183C204.02 325.183 204.103 325.183 204.131 325.183C205.407 325.072 205.656 323.352 205.74 321.854C205.906 319.33 205.823 316.833 205.518 314.253Z"
        fill="#91E7F2"
      />
      <path
        d="M225.57 332.452C226.818 332.146 226.791 330.399 226.624 328.901C226.347 326.376 225.875 323.935 225.154 321.438C223.268 314.975 220.245 308.178 219.885 307.401C219.691 306.985 219.385 306.93 219.247 306.93C219.053 306.93 218.858 307.013 218.72 307.152C218.581 307.318 218.526 307.512 218.526 307.762C218.581 308.594 219.191 316.029 220.634 322.576C221.188 325.128 221.937 327.486 222.936 329.844C223.712 331.675 224.378 332.479 225.238 332.479C225.349 332.479 225.459 332.479 225.57 332.452Z"
        fill="#91E7F2"
      />
      <path
        d="M271.971 318.692C268.31 313.254 263.595 307.956 262.68 306.929C262.347 306.569 261.792 306.596 261.543 306.957C261.46 307.068 261.349 307.318 261.515 307.706C262.042 308.954 264.76 315.529 268.061 321.161C269.364 323.408 270.806 325.433 272.471 327.375C273.552 328.651 274.357 329.233 275.078 329.233C275.327 329.233 275.577 329.178 275.771 329.039C276.853 328.373 276.298 326.709 275.688 325.35C274.634 323.019 273.441 320.828 271.971 318.692Z"
        fill="#007FC8"
      />
      <path
        d="M247.398 297.386C248.591 299.717 249.894 301.825 251.42 303.85C252.474 305.237 253.25 305.847 253.999 305.875C254.221 305.875 254.443 305.82 254.637 305.736C255.746 305.126 255.303 303.434 254.776 302.047C253.888 299.661 252.806 297.414 251.475 295.167C248.147 289.536 243.737 283.96 242.877 282.878C242.683 282.628 242.461 282.573 242.295 282.573C242.073 282.573 241.851 282.683 241.74 282.85C241.657 282.961 241.546 283.211 241.657 283.571C241.934 284.348 244.375 291.394 247.398 297.386Z"
        fill="#007FC8"
      />
      <path
        d="M283.953 303.101C285.118 304.072 285.922 304.544 286.588 304.544C286.921 304.544 287.226 304.433 287.475 304.238C288.446 303.406 287.614 301.853 286.81 300.604C285.423 298.468 283.898 296.499 282.095 294.612C277.518 289.73 271.722 285.014 271.056 284.487C271.001 284.431 270.834 284.292 270.585 284.292C270.335 284.292 270.085 284.431 269.974 284.625C269.919 284.736 269.78 285.041 270.03 285.402C270.446 286.096 274.357 292.448 278.628 297.664C280.264 299.661 282.012 301.437 283.953 303.101Z"
        fill="white"
        fill-opacity="0.5"
      />
      <path
        d="M309.83 268.979C311.494 269.839 312.631 270.255 313.38 270.255C314.046 270.283 314.351 269.978 314.517 269.728C315.183 268.702 313.962 267.398 311.91 265.761C309.081 263.514 306.197 261.461 303.035 259.52C294.881 254.471 285.34 249.782 284.23 249.255C284.147 249.2 284.009 249.144 283.842 249.144C283.565 249.144 283.287 249.311 283.176 249.588C283.121 249.727 283.038 250.087 283.482 250.448C284.397 251.197 292.551 257.994 300.483 263.403C303.562 265.539 306.613 267.343 309.83 268.979Z"
        fill="#91E7F2"
      />
      <path
        d="M290.379 270.699C285.192 266.454 278.813 262.57 278.092 262.127C277.704 261.877 277.232 262.016 277.066 262.376C277.011 262.487 276.9 262.82 277.205 263.181C277.732 263.819 282.447 269.617 287.356 274.194C289.27 275.969 291.239 277.495 293.374 278.882C294.511 279.604 295.316 279.964 295.954 279.964C296.508 279.964 296.841 279.742 297.008 279.52C297.84 278.549 296.841 277.135 295.87 275.997C294.179 274.083 292.404 272.335 290.379 270.699Z"
        fill="#007FC8"
      />
      <path
        d="M293.466 320.051C292.163 317.887 290.665 315.862 288.973 313.92C284.646 309.01 279.321 304.349 278.267 303.434C277.907 303.129 277.38 303.212 277.186 303.573C277.102 303.711 276.991 303.989 277.213 304.322C277.629 305.043 281.318 311.507 285.423 316.861C287.004 318.914 288.696 320.745 290.582 322.464C291.719 323.519 292.551 323.99 293.216 323.99C293.549 323.99 293.827 323.907 294.076 323.713C294.992 322.908 294.243 321.327 293.466 320.051Z"
        fill="#007FC8"
      />
      <path
        d="M244.07 323.13C241.518 316.916 237.774 310.452 237.358 309.731C237.164 309.398 236.886 309.343 236.748 309.343C236.553 309.343 236.332 309.426 236.221 309.592C236.11 309.703 236.027 309.925 236.082 310.258C236.221 311.063 237.607 318.414 239.771 324.767C240.603 327.236 241.601 329.511 242.822 331.758C243.737 333.422 244.458 334.143 245.262 334.143C245.429 334.143 245.567 334.116 245.734 334.06C246.926 333.616 246.732 331.869 246.399 330.426C245.817 327.929 245.068 325.544 244.07 323.13Z"
        fill="white"
        fill-opacity="0.5"
      />
      <path
        d="M131.853 293.419C132.075 293.53 132.325 293.614 132.574 293.614C133.573 293.641 134.71 292.754 136.235 290.756C138.537 287.76 140.534 284.653 142.309 281.213C146.913 272.364 150.658 261.878 151.074 260.685C151.185 260.407 151.157 260.158 151.018 259.963C150.907 259.797 150.685 259.658 150.463 259.658C150.214 259.658 150.02 259.769 149.826 259.991C149.049 260.934 142.032 269.59 136.873 278.134C134.876 281.435 133.24 284.764 131.881 288.287C131.104 290.257 130.439 292.615 131.853 293.419Z"
        fill="#91E7F2"
      />
      <path
        d="M119.566 314.974C119.761 315.085 119.955 315.141 120.177 315.141C120.814 315.169 121.48 314.725 122.284 313.754C123.255 312.589 124.143 311.479 125.141 309.509C127.249 305.348 128.747 300.466 129.052 299.495C129.107 299.301 129.08 299.023 128.969 298.857C128.858 298.69 128.636 298.579 128.414 298.579C128.22 298.579 127.97 298.69 127.832 298.857C127.665 299.023 124.032 303.24 121.425 307.457C120.343 309.232 119.677 310.564 119.178 312.034C118.984 312.589 118.402 314.309 119.566 314.974Z"
        fill="#3BB2F4"
      />
      <path
        d="M185.243 309.177C184.938 309.177 184.633 309.426 184.55 309.731C184.495 309.953 183.052 315.28 182.525 320.273C182.304 322.354 182.304 323.824 182.498 325.377C182.581 325.988 182.803 327.791 184.134 327.874H184.189C185.493 327.902 185.909 326.154 186.048 325.572C186.408 324.101 186.713 322.714 186.769 320.495C186.852 315.834 186.075 310.758 185.909 309.787C185.854 309.454 185.549 309.177 185.243 309.177Z"
        fill="#91E7F2"
      />
      <path
        d="M191.317 301.714C191.151 296.915 190.014 291.672 189.875 291.061C189.82 290.756 189.515 290.479 189.21 290.479C188.877 290.479 188.572 290.756 188.516 291.061C188.461 291.283 187.324 296.665 187.074 301.686C186.963 303.767 187.046 305.237 187.324 306.791C187.434 307.373 187.74 309.176 189.071 309.204H189.099C190.43 309.204 190.79 307.401 190.901 306.818C191.179 305.32 191.401 303.933 191.317 301.714Z"
        fill="#91E7F2"
      />
      <path
        d="M207.154 332.84C207.043 332.451 206.738 332.313 206.489 332.313C206.184 332.313 205.823 332.507 205.768 332.979C205.768 333.062 204.88 340.774 205.241 347.82C205.379 350.677 205.684 352.703 206.267 354.783C206.627 356.004 207.238 357.641 208.569 357.696C208.624 357.696 208.68 357.696 208.763 357.696C210.399 357.557 210.621 355.172 210.677 354.395C210.871 352.314 210.982 350.372 210.566 347.349C209.734 340.885 207.598 334.144 207.154 332.84Z"
        fill="#91E7F2"
      />
      <path
        d="M322.339 256.69C323.337 256.718 324.03 256.385 324.335 255.747C325.057 254.277 323.171 252.723 321.451 251.53C318.345 249.394 315.127 247.591 311.605 245.982C302.48 241.848 291.83 238.714 290.637 238.353C290.554 238.325 290.471 238.298 290.388 238.298C290.083 238.298 289.805 238.492 289.694 238.769C289.667 238.88 289.556 239.296 290.055 239.629C291.691 240.822 300.261 246.981 308.859 251.586C312.271 253.417 315.682 254.859 319.26 256.024C320.536 256.468 321.562 256.69 322.339 256.69Z"
        fill="#3BB2F4"
      />
      <path
        d="M335.818 261.822C332.323 260.185 328.302 258.965 327.858 258.826C327.802 258.798 327.691 258.798 327.636 258.798C327.331 258.798 327.054 258.993 326.97 259.27C326.887 259.547 327.026 259.908 327.248 260.074C327.858 260.546 331.103 262.932 334.376 264.707C335.679 265.429 336.983 265.983 338.37 266.455C338.924 266.649 339.34 266.732 339.673 266.732C340.394 266.76 340.699 266.372 340.81 266.15C341.282 265.207 340.117 264.374 339.59 264.014C338.397 263.154 337.177 262.46 335.818 261.822Z"
        fill="#3BB2F4"
      />
      <path
        d="M358.699 272.031C357.535 271.171 356.314 270.45 354.955 269.784C351.627 268.175 347.799 266.899 347.051 266.649C346.718 266.538 346.274 266.732 346.163 267.065C346.08 267.343 346.191 267.676 346.413 267.87C346.773 268.147 350.157 270.727 353.457 272.614C354.761 273.363 356.037 273.945 357.424 274.417C357.978 274.611 358.422 274.722 358.783 274.722C359.476 274.75 359.781 274.389 359.892 274.167C360.364 273.252 359.199 272.392 358.699 272.031Z"
        fill="white"
        fill-opacity="0.5"
      />
      <path
        d="M343.112 274.056C340.865 272.336 338.536 270.838 335.956 269.506C329.3 266.039 321.479 263.265 320.591 262.96C320.175 262.821 319.759 262.987 319.648 263.348C319.62 263.459 319.509 263.847 319.925 264.18C320.647 264.763 327.109 270.006 333.516 273.89C335.984 275.388 338.48 276.608 341.143 277.635C342.224 278.051 343.057 278.245 343.667 278.273C344.582 278.3 344.998 277.884 345.164 277.551C345.802 276.359 344.388 275.055 343.112 274.056Z"
        fill="#007FC8"
      />
      <path
        d="M315.793 242.542C316.903 242.819 317.79 242.958 318.511 242.958C319.759 242.986 320.536 242.625 320.841 241.876C321.451 240.35 319.454 238.936 317.651 237.881C314.379 235.995 311.023 234.414 307.417 233.11C298.015 229.698 287.143 227.34 285.922 227.09C285.839 227.062 285.784 227.062 285.728 227.062C285.423 227.062 285.118 227.256 285.035 227.562C285.007 227.645 284.896 228.089 285.451 228.422C286.505 229.06 295.99 234.885 305.115 238.908C308.637 240.461 312.132 241.654 315.793 242.542Z"
        fill="#91E7F2"
      />
      <path
        d="M303.839 228.227C304.588 228.671 308.582 230.918 312.52 232.5C314.074 233.138 315.627 233.581 317.263 233.942C317.707 234.025 318.095 234.081 318.4 234.081C319.371 234.109 319.731 233.692 319.87 233.332C320.258 232.278 318.844 231.501 318.262 231.196C316.819 230.419 315.322 229.781 313.713 229.254C309.58 227.895 304.838 227.062 304.311 226.951C303.978 226.896 303.59 227.118 303.479 227.451C303.395 227.728 303.562 228.089 303.839 228.227Z"
        fill="#3BB2F4"
      />
      <path
        d="M367.519 250.282C366.077 249.505 364.579 248.867 362.971 248.34C358.838 246.981 354.095 246.148 353.568 246.037C353.236 245.982 352.847 246.204 352.736 246.537C352.653 246.842 352.82 247.175 353.097 247.341C353.568 247.619 357.729 249.977 361.778 251.613C363.331 252.251 364.884 252.695 366.521 253.056C366.964 253.139 367.353 253.195 367.686 253.195C368.656 253.222 369.017 252.806 369.155 252.446C369.516 251.364 368.129 250.615 367.519 250.282Z"
        fill="#91E7F2"
      />
      <path
        d="M361.001 236.688C361.223 236.716 361.445 236.716 361.639 236.716C362.055 236.716 363.303 236.744 363.525 235.801C363.803 234.691 362.305 234.109 361.695 233.859C360.169 233.249 358.616 232.777 356.952 232.416C352.708 231.529 347.91 231.196 347.3 231.168C346.967 231.168 346.662 231.39 346.579 231.723C346.524 232.028 346.718 232.389 347.023 232.527C347.522 232.749 351.932 234.663 356.12 235.828C357.784 236.245 359.365 236.522 361.001 236.688Z"
        fill="#3BB2F4"
      />
      <path
        d="M341.032 212.859C339.451 212.387 337.87 212.054 336.178 211.86C334.487 211.666 332.545 211.555 330.382 211.5C328.413 211.444 326.943 211.5 326.527 211.5C326.194 211.5 325.861 211.749 325.833 212.082C325.805 212.359 325.916 212.72 326.36 212.859C327.192 213.136 331.574 214.551 335.707 215.3C337.177 215.577 338.591 215.716 340.061 215.744C340.255 215.744 340.45 215.744 340.644 215.744C341.337 215.744 342.918 215.744 343.084 214.634C343.223 213.525 341.698 213.053 341.032 212.859Z"
        fill="#007FC8"
      />
      <path
        d="M374.786 221.597C373.233 221.07 371.652 220.71 369.988 220.46C366.715 219.988 363.22 219.85 361.39 219.794C360.78 219.794 360.419 219.766 360.336 219.766C360.003 219.766 359.67 220.016 359.615 220.321C359.559 220.599 359.698 220.959 360.114 221.098C360.946 221.403 365.245 222.957 369.35 223.817C371.014 224.177 372.595 224.371 374.231 224.427H374.37C374.952 224.427 376.505 224.482 376.699 223.373C376.921 222.319 375.424 221.819 374.786 221.597Z"
        fill="#007FC8"
      />
      <path
        d="M316.126 131.438C315.738 131.438 315.238 131.521 314.601 131.715C313.02 132.187 311.522 132.797 310.024 133.574C306.252 135.488 302.508 138.096 301.759 138.623C301.509 138.789 301.371 139.122 301.454 139.4C301.537 139.677 301.815 139.871 302.092 139.899C302.147 139.899 302.286 139.871 302.342 139.871C302.869 139.733 307.5 138.429 311.466 136.737C313.02 136.071 314.434 135.294 315.821 134.379C316.376 133.99 317.707 133.13 317.235 132.104C317.152 131.854 316.875 131.466 316.126 131.438Z"
        fill="#007FC8"
      />
      <path
        d="M291.968 113.462C292.44 112.962 293.522 111.797 292.8 110.91C292.606 110.66 292.301 110.521 291.941 110.521C291.469 110.521 290.914 110.715 290.11 111.187C288.696 112.019 287.364 112.962 286.089 114.072C282.899 116.819 279.848 120.231 279.238 120.896C279.072 121.091 278.988 121.424 279.099 121.645C279.21 121.895 279.46 122.062 279.71 122.062C279.82 122.062 279.987 122.006 280.098 121.951C280.569 121.701 284.757 119.371 288.252 116.735C289.639 115.764 290.831 114.682 291.968 113.462Z"
        fill="#3BB2F4"
      />
      <path
        d="M300.455 58.7282C297.71 61.9185 295.213 65.7745 294.742 66.5235C294.603 66.7177 294.576 67.0229 294.686 67.2448C294.797 67.4667 295.019 67.6054 295.269 67.6054C295.435 67.6054 295.63 67.5222 295.74 67.439C296.434 66.9119 300.039 64.0823 303.007 61.0585C304.2 59.8379 305.226 58.5895 306.141 57.2302C306.529 56.6754 307.417 55.3438 306.585 54.5948C306.391 54.4283 306.141 54.3174 305.892 54.3174C305.392 54.3174 304.81 54.5948 304.006 55.2606C302.702 56.3148 301.537 57.4521 300.455 58.7282Z"
        fill="#3BB2F4"
      />
      <path
        d="M273.081 60.0598C273.192 60.254 273.414 60.3649 273.636 60.3927C273.83 60.3927 274.052 60.2817 274.19 60.143C274.579 59.7546 277.934 56.3147 280.514 52.8193C281.512 51.46 282.372 50.0729 283.093 48.6026C283.398 47.9923 284.092 46.5775 283.176 45.9117C283.01 45.8008 282.816 45.7453 282.594 45.7175C282.039 45.7175 281.485 46.0782 280.68 46.9104C279.571 48.131 278.572 49.4071 277.657 50.8219C275.327 54.456 273.275 58.8391 273.053 59.3385C272.97 59.5604 272.97 59.8656 273.081 60.0598Z"
        fill="white"
        fill-opacity="0.5"
      />
      <path
        d="M328.801 86.9134C328.912 86.9134 329.05 86.8856 329.161 86.8301C329.633 86.6082 333.987 84.5276 337.621 82.1696C339.035 81.2541 340.311 80.2555 341.504 79.1181C342.003 78.6465 343.168 77.5646 342.502 76.6214C342.391 76.4549 342.114 76.1775 341.587 76.1775C341.143 76.1775 340.588 76.3439 339.812 76.7323C338.342 77.4813 336.983 78.3413 335.624 79.34C332.24 81.8922 328.995 85.1102 328.357 85.7205C328.163 85.9147 328.08 86.2476 328.191 86.4972C328.302 86.7469 328.551 86.9134 328.801 86.9134Z"
        fill="white"
        fill-opacity="0.5"
      />
      <path
        d="M295.047 52.958C295.214 52.958 295.435 52.8748 295.546 52.7638C295.935 52.4032 299.54 49.2407 302.369 45.9117C303.479 44.6356 304.394 43.304 305.226 41.8892C305.559 41.3067 306.363 39.9196 305.476 39.2261C305.309 39.0874 305.087 39.0042 304.838 39.0042C304.311 39.0042 303.756 39.3093 302.924 40.0861C301.731 41.2235 300.65 42.4163 299.623 43.7756C297.016 47.271 294.687 51.4877 294.437 51.9316C294.326 52.1258 294.298 52.4309 294.409 52.6529C294.603 52.8193 294.825 52.9303 295.047 52.958Z"
        fill="#91E7F2"
      />
      <path
        d="M365.605 180.152C365.55 179.014 363.941 178.876 363.276 178.82C361.612 178.682 360.003 178.682 358.339 178.848C353.984 179.236 349.325 180.318 348.798 180.457C348.465 180.54 348.215 180.845 348.215 181.178C348.243 181.456 348.437 181.789 348.881 181.844C349.464 181.9 352.598 182.26 356.009 182.343C356.897 182.371 357.756 182.371 358.533 182.343C360.225 182.288 361.833 182.121 363.442 181.789C364.108 181.594 365.689 181.289 365.605 180.152Z"
        fill="#007FC8"
      />
      <path
        d="M377.975 187.808C376.589 187.586 375.341 187.503 373.815 187.559C370.265 187.642 366.438 188.28 365.689 188.391C365.356 188.446 365.079 188.752 365.079 189.084C365.079 189.417 365.356 189.722 365.689 189.778C366.133 189.861 370.126 190.582 373.787 190.721H374.203C375.479 190.749 376.727 190.666 377.975 190.499C378.558 190.416 379.972 190.222 379.972 189.168C379.945 188.113 378.558 187.919 377.975 187.808Z"
        fill="white"
        fill-opacity="0.5"
      />
      <path
        d="M306.89 168.611C306.668 168.611 306.446 168.611 306.169 168.639C304.532 168.75 302.924 169 301.287 169.416C297.183 170.442 292.939 172.134 292.107 172.467C291.802 172.578 291.608 172.939 291.663 173.244C291.719 173.549 292.024 173.799 292.357 173.799C292.357 173.799 292.412 173.799 292.44 173.799C293.327 173.771 297.876 173.549 302.036 172.8C303.7 172.495 305.254 172.079 306.807 171.496C307.445 171.247 308.942 170.692 308.693 169.61C308.526 168.972 307.916 168.639 306.89 168.611Z"
        fill="#91E7F2"
      />
      <path
        d="M363.581 164.561C363.636 164.894 363.941 165.144 364.274 165.172C364.357 165.172 364.69 165.199 365.217 165.199C366.715 165.227 369.655 165.255 372.373 164.977C373.787 164.839 375.119 164.589 376.45 164.201C377.032 164.034 378.364 163.646 378.225 162.619C378.086 161.593 376.699 161.565 376.2 161.565H376.089C374.703 161.537 373.344 161.648 371.957 161.898C368.462 162.481 364.801 163.618 364.08 163.84C363.775 163.895 363.553 164.228 363.581 164.561Z"
        fill="#91E7F2"
      />
      <path
        d="M305.975 148.499C305.642 148.499 305.226 148.527 304.727 148.638C303.118 148.943 301.565 149.387 299.984 149.997C296.018 151.523 292.024 153.714 291.248 154.13C290.97 154.269 290.804 154.63 290.887 154.907C290.97 155.212 291.248 155.434 291.553 155.434C291.58 155.434 291.691 155.434 291.719 155.407C292.246 155.323 297.016 154.547 301.149 153.243C302.758 152.743 304.255 152.133 305.725 151.384C306.335 151.079 307.75 150.33 307.362 149.276C307.223 148.943 306.89 148.527 305.975 148.499Z"
        fill="#91E7F2"
      />
      <path
        d="M274.412 145.614C274.939 145.17 276.159 144.144 275.549 143.173C275.438 142.978 275.161 142.673 274.578 142.673C274.162 142.673 273.608 142.812 272.887 143.145C271.389 143.811 269.947 144.587 268.56 145.531C264.954 147.944 261.349 151.134 260.961 151.495C260.766 151.689 260.655 152.022 260.739 152.272C260.822 152.521 261.099 152.716 261.377 152.716C261.46 152.716 261.626 152.688 261.709 152.66C262.514 152.327 266.757 150.58 270.418 148.471C271.833 147.611 273.164 146.668 274.412 145.614Z"
        fill="#007FC8"
      />
      <path
        d="M258.326 123.504C258.742 122.977 259.712 121.701 258.908 120.896C258.714 120.702 258.464 120.591 258.159 120.591C257.688 120.591 257.105 120.841 256.273 121.424C254.942 122.394 253.722 123.476 252.557 124.697C249.644 127.749 246.926 131.466 246.427 132.187C246.288 132.381 246.233 132.714 246.344 132.936C246.455 133.158 246.677 133.297 246.926 133.325C247.065 133.325 247.259 133.269 247.37 133.186C247.814 132.881 251.752 130.162 254.97 127.194C256.218 126.001 257.327 124.808 258.326 123.504Z"
        fill="#91E7F2"
      />
      <path
        d="M344.194 130.717C344.249 130.717 344.333 130.717 344.388 130.689C345.248 130.523 349.741 129.663 353.763 128.303C355.371 127.748 356.841 127.11 358.283 126.306C358.866 125.973 360.28 125.224 359.864 124.142C359.753 123.809 359.42 123.421 358.561 123.393C358.2 123.393 357.784 123.449 357.229 123.56C355.621 123.92 354.068 124.392 352.514 125.03C348.632 126.639 344.665 128.941 343.889 129.385C343.639 129.524 343.473 129.885 343.528 130.162C343.611 130.495 343.889 130.717 344.194 130.717Z"
        fill="#3BB2F4"
      />
      <path
        d="M323.587 100.257C323.698 100.507 323.947 100.673 324.197 100.701C324.308 100.701 324.447 100.673 324.557 100.618C325.362 100.257 329.522 98.3428 333.072 96.068C334.487 95.1525 335.79 94.1816 336.983 93.0719C337.482 92.6003 338.647 91.5462 338.009 90.603C337.898 90.4365 337.621 90.1313 337.066 90.1313C336.622 90.1313 336.068 90.2978 335.319 90.6584C333.849 91.3797 332.462 92.2119 331.103 93.2106C327.581 95.7906 324.141 99.1195 323.753 99.5079C323.587 99.6744 323.504 100.007 323.587 100.257Z"
        fill="#007FC8"
      />
      <path
        d="M285.368 189.196C284.175 189.001 283.01 188.918 281.817 188.89C281.374 188.89 280.902 188.89 280.431 188.89C276.076 189.001 271.334 189.778 270.807 189.861C270.474 189.917 270.196 190.222 270.196 190.555C270.196 190.888 270.474 191.193 270.807 191.248C271.334 191.332 276.076 192.192 280.403 192.358C280.569 192.358 280.736 192.358 280.93 192.386C282.428 192.414 283.87 192.33 285.34 192.136C286.005 192.053 287.586 191.831 287.586 190.694C287.614 189.556 286.033 189.306 285.368 189.196Z"
        fill="white"
        fill-opacity="0.5"
      />
      <path
        d="M284.979 232.139C282.76 230.779 280.486 229.614 278.045 228.588C271.722 225.925 264.372 223.872 263.54 223.622C263.124 223.511 262.736 223.705 262.625 224.066C262.597 224.149 262.486 224.593 262.985 224.898C263.706 225.37 270.085 229.503 276.298 232.527C278.711 233.692 281.069 234.636 283.537 235.385C284.48 235.662 285.201 235.828 285.756 235.828C286.699 235.856 287.087 235.44 287.254 235.107C287.725 234.025 286.366 232.999 284.979 232.139Z"
        fill="white"
        fill-opacity="0.5"
      />
      <path
        d="M348.659 261.628C349.769 261.656 350.24 261.184 350.406 260.768C350.961 259.52 349.463 258.354 348.077 257.467C345.692 255.941 343.223 254.665 340.533 253.555C333.599 250.698 325.556 248.59 324.641 248.368C324.224 248.257 323.836 248.451 323.725 248.811C323.642 249.061 323.698 249.394 324.086 249.644C325.334 250.504 331.907 254.915 338.453 258.132C341.06 259.409 343.639 260.407 346.357 261.212C347.356 261.461 348.077 261.6 348.659 261.628Z"
        fill="#3BB2F4"
      />
      <path
        d="M265.315 219.406C265.509 219.544 270.169 222.513 274.745 224.482C276.659 225.315 278.073 225.758 279.626 226.036C280.042 226.119 280.375 226.147 280.68 226.147C281.873 226.175 282.344 225.647 282.511 225.231C282.982 223.983 281.429 223.012 280.902 222.707C279.599 221.93 278.378 221.209 276.27 220.515C271.722 218.99 266.397 218.213 265.814 218.13C265.481 218.074 265.093 218.296 265.01 218.601C264.899 218.879 265.065 219.239 265.315 219.406Z"
        fill="#91E7F2"
      />
      <path
        d="M336.927 247.841C339.007 248.645 340.505 249.089 342.169 249.339C342.53 249.394 342.835 249.422 343.14 249.422C344.249 249.45 344.943 249.089 345.165 248.395C345.608 247.092 343.944 246.121 343.39 245.815C341.975 245.011 340.644 244.317 338.397 243.624C333.488 242.154 327.775 241.516 327.137 241.432C326.804 241.405 326.443 241.599 326.332 241.932C326.277 242.181 326.305 242.514 326.693 242.736C327.22 243.097 332.129 245.954 336.927 247.841Z"
        fill="#007FC8"
      />
      <path
        d="M298.847 224.926C299.707 225.065 300.455 225.148 301.066 225.176C301.648 225.176 303.423 225.232 303.839 223.928C304.338 222.346 302.258 221.098 300.372 220.155C296.989 218.49 293.522 217.159 289.833 216.077C280.458 213.358 270.03 211.832 267.978 211.527C267.922 211.527 267.867 211.527 267.839 211.527C267.534 211.527 267.201 211.749 267.118 212.054C267.09 212.138 267.007 212.609 267.562 212.914C268.643 213.497 278.461 218.657 287.891 222.041C291.552 223.345 295.13 224.288 298.847 224.926Z"
        fill="#007FC8"
      />
      <path
        d="M348.215 237.049C345.636 235.856 343.029 234.913 340.228 234.192C332.933 232.278 324.724 231.279 323.781 231.196C323.725 231.196 323.698 231.196 323.67 231.196C323.365 231.196 323.032 231.418 322.949 231.723C322.893 231.973 322.949 232.333 323.392 232.555C324.224 232.971 331.658 236.661 338.785 239.019C341.531 239.934 344.249 240.572 347.051 240.988C347.633 241.072 348.132 241.127 348.576 241.127C349.103 241.127 350.628 241.155 350.961 240.018C351.35 238.686 349.713 237.743 348.215 237.049Z"
        fill="white"
        fill-opacity="0.5"
      />
      <path
        d="M325.278 228.366C326.637 228.394 327.442 228.006 327.664 227.201C328.08 225.786 326.221 224.843 325.611 224.538C324.03 223.733 322.533 223.012 320.036 222.374C314.739 221.042 308.748 220.571 307.611 220.515C307.611 220.515 307.556 220.515 307.528 220.515C307.223 220.515 306.89 220.737 306.807 221.042C306.724 221.348 306.918 221.708 307.195 221.847C307.445 221.986 313.158 225.065 318.677 226.951C320.979 227.728 322.671 228.144 324.474 228.338C324.779 228.338 325.029 228.366 325.278 228.366Z"
        fill="#007FC8"
      />
      <path
        d="M352.598 227.257C352.875 227.284 353.152 227.284 353.374 227.284C354.983 227.312 357.146 227.201 357.451 225.675C357.784 224.066 355.565 223.04 353.596 222.319C350.046 221.043 346.468 220.099 342.668 219.434C332.822 217.741 321.701 217.381 320.425 217.353C319.925 217.353 319.704 217.658 319.648 217.908C319.593 218.185 319.704 218.546 320.203 218.74C321.368 219.184 331.741 223.234 341.42 225.564C345.165 226.508 348.826 227.035 352.598 227.257Z"
        fill="#91E7F2"
      />
      <path
        d="M310.302 215.633C310.579 214.301 308.832 213.497 307.306 212.942C304.644 211.971 301.953 211.278 299.096 210.778C291.719 209.502 283.398 209.253 282.428 209.225C281.984 209.225 281.734 209.502 281.679 209.78C281.623 210.057 281.762 210.418 282.178 210.612C283.592 211.167 290.97 214.024 298.07 215.716C300.899 216.382 303.645 216.798 306.474 216.965C306.696 216.965 306.89 216.992 307.084 216.992C307.889 216.992 310.024 217.02 310.302 215.633Z"
        fill="#91E7F2"
      />
      <path
        d="M269.586 204.12C269.808 204.204 275.022 206.09 279.904 207.006C281.457 207.311 282.677 207.449 283.842 207.477C284.23 207.477 284.619 207.477 284.979 207.477C285.589 207.449 287.42 207.394 287.614 206.062C287.808 204.731 286.061 204.148 285.506 203.954C284.064 203.482 282.705 203.038 280.514 202.817C279.349 202.706 278.073 202.622 276.686 202.595C273.275 202.511 270.335 202.733 269.78 202.789C269.447 202.817 269.142 203.066 269.087 203.371C269.031 203.677 269.281 204.009 269.586 204.12Z"
        fill="#007FC8"
      />
      <path
        d="M270.058 178.432C270.113 178.432 275.716 178.349 280.736 177.433C282.788 177.045 284.203 176.657 285.645 176.019C286.199 175.769 287.891 175.048 287.586 173.744C287.42 172.967 286.671 172.551 285.367 172.523C285.201 172.523 285.035 172.523 284.84 172.523C283.343 172.579 281.901 172.69 279.765 173.244C275.244 174.465 270.612 176.629 269.725 177.073C269.447 177.211 269.253 177.544 269.309 177.85C269.364 178.155 269.697 178.404 270.002 178.404C270.03 178.432 270.03 178.432 270.058 178.432Z"
        fill="#007FC8"
      />
      <path
        d="M266.563 164.367C266.591 164.367 266.674 164.367 266.702 164.367C266.923 164.339 272.443 163.618 277.186 162.148C279.182 161.537 280.541 160.983 281.9 160.206C282.427 159.901 284.008 158.985 283.565 157.737C283.426 157.293 282.982 156.794 281.845 156.766C281.512 156.766 281.152 156.794 280.708 156.849C279.21 157.071 277.823 157.348 275.743 158.153C271.278 159.928 266.702 162.758 266.202 163.063C265.953 163.23 265.786 163.562 265.87 163.868C265.98 164.145 266.286 164.339 266.563 164.367Z"
        fill="#91E7F2"
      />
      <path
        d="M268.532 133.824C268.948 133.38 270.169 132.021 269.337 130.939C269.17 130.717 268.837 130.439 268.227 130.412C267.728 130.412 267.118 130.578 266.341 130.994C264.982 131.688 263.734 132.381 262.042 133.796C258.353 136.875 254.914 141.009 254.526 141.48C254.359 141.675 254.304 142.008 254.387 142.257C254.498 142.507 254.748 142.646 254.997 142.673C255.108 142.673 255.247 142.646 255.358 142.59C255.58 142.479 260.572 140.066 264.622 137.181C266.341 135.988 267.478 135.017 268.532 133.824Z"
        fill="#91E7F2"
      />
      <path
        d="M285.451 95.0417C284.73 94.3204 283.537 94.5978 281.568 96.0681C279.71 97.4829 277.99 98.8422 275.688 101.339C270.612 106.832 265.87 113.656 265.343 114.433C265.093 114.766 265.176 115.071 265.26 115.21C265.371 115.432 265.62 115.57 265.87 115.598C266.119 115.598 266.286 115.487 266.341 115.432C266.619 115.21 273.497 110.188 279.183 104.807C281.512 102.587 283.038 100.895 284.48 98.9532C285.451 97.6216 286.422 95.9849 285.451 95.0417Z"
        fill="#91E7F2"
      />
      <path
        d="M340.533 120.563C340.144 120.563 339.701 120.619 339.118 120.758C337.648 121.118 336.262 121.479 334.292 122.45C330.104 124.503 325.972 127.527 325.167 128.109C324.918 128.276 324.807 128.636 324.89 128.914C324.973 129.191 325.251 129.385 325.556 129.413C325.611 129.413 325.694 129.413 325.75 129.385C325.972 129.33 331.408 128.165 336.04 126.361C337.981 125.612 339.285 124.919 340.588 124.031C341.087 123.698 342.613 122.644 342.058 121.423C341.864 121.035 341.476 120.591 340.533 120.563Z"
        fill="#91E7F2"
      />
      <path
        d="M350.046 113.323C350.102 113.323 350.185 113.323 350.24 113.295C350.268 113.295 353.957 112.408 357.091 111.076C358.394 110.521 359.254 110.05 360.142 109.411C360.502 109.162 361.612 108.357 361.14 107.359C361.029 107.109 360.724 106.721 359.975 106.693C359.67 106.693 359.337 106.748 358.921 106.859C357.923 107.109 357.008 107.386 355.676 108.108C352.903 109.578 350.185 111.659 349.658 112.075C349.436 112.241 349.325 112.574 349.408 112.851C349.491 113.129 349.769 113.323 350.046 113.323Z"
        fill="white"
        fill-opacity="0.5"
      />
      <path
        d="M362 212.443C362.194 211.111 360.447 210.529 359.892 210.334C358.45 209.863 357.091 209.419 354.9 209.197C353.735 209.086 352.459 209.003 351.072 208.975C347.689 208.892 344.749 209.114 344.166 209.169C343.861 209.197 343.528 209.447 343.5 209.78C343.445 210.085 343.667 210.418 343.972 210.529C344.027 210.556 349.269 212.47 354.289 213.414C355.87 213.719 357.063 213.857 358.228 213.885C358.616 213.885 359.004 213.885 359.365 213.885C359.947 213.83 361.806 213.774 362 212.443Z"
        fill="white"
        fill-opacity="0.5"
      />
      <path
        d="M297.571 201.679C299.679 201.734 301.731 201.679 303.839 201.513C305.919 201.346 308.332 200.958 308.443 199.293C308.554 197.656 306.224 196.935 304.172 196.491C300.483 195.715 296.822 195.243 292.939 195.132C282.954 194.799 271.86 195.937 270.64 196.075C270.141 196.131 269.947 196.464 269.919 196.741C269.891 197.018 270.058 197.379 270.557 197.49C271.749 197.795 282.538 200.375 292.523 201.374C294.215 201.54 295.907 201.623 297.571 201.679Z"
        fill="#3BB2F4"
      />
      <path
        d="M345.636 186.199C345.608 184.563 343.223 184.036 341.143 183.758C337.371 183.287 333.71 183.148 329.855 183.342C319.87 183.841 308.942 185.894 307.722 186.144C307.223 186.227 307.056 186.588 307.056 186.865C307.056 187.143 307.251 187.503 307.778 187.559C309.691 187.864 319.676 189.362 329.245 189.584H329.966C333.821 189.639 337.51 189.39 341.254 188.779C343.306 188.446 345.664 187.864 345.636 186.199Z"
        fill="#3BB2F4"
      />
      <path
        d="M304.865 160.206C304.505 158.902 302.785 158.874 302.203 158.847C301.565 158.819 300.788 158.874 299.901 159.013C296.157 159.512 292.551 160.345 288.862 161.51C279.377 164.561 269.337 169.36 268.199 169.915C267.645 170.165 267.728 170.637 267.728 170.748C267.783 171.053 268.116 171.302 268.421 171.302C268.449 171.302 268.505 171.302 268.588 171.302C269.808 171.164 280.874 169.971 290.554 167.557C294.298 166.614 297.793 165.421 301.232 163.868C303.174 163.008 305.309 161.815 304.865 160.206Z"
        fill="white"
        fill-opacity="0.5"
      />
      <path
        d="M312.992 150.718C312.465 150.996 312.52 151.44 312.548 151.551C312.604 151.856 312.936 152.078 313.241 152.105C313.297 152.105 313.352 152.105 313.436 152.105C314.656 151.939 325.695 150.524 335.319 147.889C339.035 146.862 342.53 145.586 345.941 143.977C347.827 143.089 349.935 141.841 349.464 140.26C349.214 139.428 348.354 138.984 346.94 138.956C346.274 138.928 345.442 139.011 344.471 139.15C340.755 139.733 337.149 140.62 333.488 141.869C324.03 145.115 314.101 150.136 312.992 150.718Z"
        fill="#007FC8"
      />
      <path
        d="M312.243 105.75C311.411 105.722 310.218 106.083 308.637 106.776C305.198 108.33 301.981 110.161 298.791 112.352C290.582 118.011 282.344 125.502 281.429 126.362C281.068 126.694 281.124 127.055 281.179 127.194C281.263 127.443 281.54 127.638 281.817 127.638C281.928 127.638 282.067 127.61 282.233 127.554C283.371 127.083 293.633 122.727 302.175 117.623C305.503 115.653 308.499 113.49 311.355 110.993C312.936 109.606 314.628 107.858 313.741 106.471C313.546 106.166 313.103 105.778 312.243 105.75Z"
        fill="#91E7F2"
      />
      <path
        d="M329.966 111.326C321.368 116.43 312.687 123.338 311.688 124.142C311.272 124.475 311.355 124.836 311.383 124.974C311.466 125.252 311.744 125.446 312.021 125.446C312.132 125.446 312.243 125.418 312.382 125.391C313.546 125.002 324.058 121.34 332.961 116.818C336.4 115.071 339.534 113.101 342.557 110.826C344.222 109.55 346.052 107.941 345.248 106.471C345.054 106.083 344.582 105.639 343.584 105.611C342.752 105.583 341.642 105.861 340.145 106.416C336.622 107.747 333.294 109.356 329.966 111.326Z"
        fill="#007FC8"
      />
      <path
        d="M263.928 157.487C264.011 157.487 264.095 157.487 264.206 157.459C265.398 157.182 276.215 154.547 285.506 150.885C289.112 149.47 292.412 147.806 295.629 145.836C297.404 144.726 299.374 143.284 298.736 141.758C298.458 141.092 297.793 140.731 296.767 140.731C295.99 140.704 294.964 140.87 293.66 141.231C290.027 142.23 286.56 143.506 283.065 145.142C274.024 149.414 264.732 155.49 263.679 156.183C263.207 156.488 263.29 156.905 263.318 157.016C263.346 157.265 263.651 157.487 263.928 157.487Z"
        fill="#3BB2F4"
      />
      <path
        d="M250.504 136.348C250.199 136.681 250.282 137.014 250.338 137.153C250.449 137.403 250.698 137.569 250.948 137.569C251.142 137.569 251.309 137.486 251.42 137.43C252.501 136.82 262.125 131.299 270.058 125.196C273.108 122.838 275.826 120.342 278.378 117.54C279.793 115.986 281.263 114.044 280.209 112.768C279.987 112.519 279.598 112.213 278.933 112.186C278.045 112.158 276.853 112.63 275.161 113.656C271.944 115.626 268.948 117.817 266.064 120.369C258.575 126.972 251.309 135.405 250.504 136.348Z"
        fill="white"
        fill-opacity="0.5"
      />
      <path
        d="M293.106 92.4895C293.216 92.7114 293.466 92.8779 293.716 92.8779C293.938 92.8779 294.076 92.7947 294.215 92.7114C295.907 91.6295 304.782 85.8871 312.187 79.5621C315.127 77.0376 317.707 74.4022 320.092 71.4894C321.423 69.8804 322.81 67.8553 321.701 66.6347C321.506 66.4127 321.118 66.1353 320.508 66.1353C319.62 66.1076 318.4 66.6347 316.708 67.7998C313.574 69.9081 310.717 72.2661 307.944 74.957C300.788 81.9201 293.965 90.7141 293.189 91.7128C292.939 92.0457 293.022 92.3508 293.106 92.4895Z"
        fill="white"
        fill-opacity="0.5"
      />
      <path
        d="M299.928 71.6557C299.069 70.7679 297.543 71.1286 294.992 72.9872C291.941 75.2065 289.167 77.6478 286.505 80.4496C279.626 87.6624 273.109 96.706 272.388 97.7325C272.138 98.0931 272.221 98.3983 272.277 98.537C272.388 98.7589 272.637 98.9253 272.887 98.9253C273.136 98.9253 273.331 98.7866 273.414 98.7311C275.078 97.5938 283.731 91.5184 290.887 84.916C293.716 82.3083 296.212 79.5619 298.514 76.5659C299.79 74.9014 301.093 72.8208 299.928 71.6557Z"
        fill="#3BB2F4"
      />
      <path
        d="M266.175 83.7231C265.981 84.056 266.064 84.3334 266.147 84.4999C266.258 84.6941 266.48 84.8328 266.729 84.8328C267.035 84.8328 267.229 84.6663 267.312 84.5831C268.227 83.7509 276.409 76.2052 282.76 68.5209C285.229 65.5526 287.309 62.501 289.167 59.1998C290.193 57.3689 291.22 55.1496 289.888 54.1509C288.918 53.4019 287.448 53.9567 285.173 56.1483C282.455 58.7837 280.042 61.5856 277.796 64.7204C271.971 72.7653 266.757 82.6135 266.175 83.7231Z"
        fill="#007FC8"
      />
      <path
        d="M237.136 125.086C237.441 125.086 237.635 124.919 237.718 124.808C239.216 123.449 246.926 116.236 253.111 108.69C255.552 105.694 257.632 102.643 259.49 99.3417C260.517 97.5107 261.515 95.2914 260.212 94.2927C259.962 94.0986 259.629 93.9876 259.296 93.9876C258.353 93.9598 257.188 94.6811 255.524 96.3179C252.834 98.9533 250.421 101.755 248.174 104.918C242.35 113.074 237.191 122.894 236.609 124.004C236.415 124.337 236.498 124.614 236.581 124.78C236.664 124.947 236.914 125.086 237.136 125.086Z"
        fill="white"
        fill-opacity="0.5"
      />
      <path
        d="M348.659 160.206C348.299 160.206 347.883 160.206 347.439 160.234C343.667 160.372 340.006 160.844 336.234 161.676C326.499 163.812 316.043 167.668 314.85 168.112C314.379 168.279 314.268 168.639 314.323 168.917C314.379 169.194 314.573 169.499 315.072 169.499H315.155C316.403 169.499 327.497 169.333 337.371 167.835C341.199 167.252 344.776 166.392 348.354 165.172C350.351 164.506 352.57 163.507 352.265 161.898C352.015 160.455 350.074 160.234 348.659 160.206Z"
        fill="#3BB2F4"
      />
      <path
        d="M350.268 204.12C350.379 202.484 348.021 201.762 345.997 201.318C342.308 200.542 338.619 200.098 334.764 199.959C325.001 199.654 314.462 200.736 312.465 200.93C311.966 200.986 311.771 201.318 311.744 201.596C311.716 201.873 311.882 202.234 312.382 202.345C313.574 202.65 324.391 205.23 334.348 206.173C336.012 206.34 337.648 206.423 339.257 206.478C341.393 206.534 343.473 206.478 345.636 206.284C347.744 206.145 350.157 205.757 350.268 204.12Z"
        fill="#007FC8"
      />
      <path
        d="M294.465 205.369C294.021 205.397 293.771 205.702 293.743 205.979C293.716 206.284 293.882 206.617 294.326 206.756C295.768 207.172 303.395 209.391 310.606 210.445C312.659 210.751 314.628 210.917 316.625 210.973C317.429 211 318.234 211 319.093 210.973C320.73 210.917 322.616 210.695 322.782 209.336C322.949 207.976 321.146 207.338 319.565 206.895C316.819 206.173 314.101 205.702 311.189 205.452C309.414 205.313 307.472 205.202 305.392 205.175C300.039 205.064 295.38 205.313 294.465 205.369Z"
        fill="white"
        fill-opacity="0.5"
      />
      <path
        d="M365.245 196.769C365.3 195.41 363.47 194.883 361.861 194.577C359.226 194.078 356.647 193.801 353.929 193.745H353.43C346.135 193.662 338.286 194.605 336.761 194.799C336.317 194.855 336.095 195.16 336.095 195.465C336.095 195.77 336.262 196.103 336.733 196.214C338.203 196.547 345.941 198.212 353.236 198.766C354.151 198.85 355.094 198.877 355.981 198.905C357.895 198.961 359.781 198.877 361.723 198.683C363.304 198.489 365.189 198.128 365.245 196.769Z"
        fill="#91E7F2"
      />
      <path
        d="M343.722 154.242C343.417 154.353 343.195 154.713 343.251 155.018C343.306 155.323 343.612 155.573 343.944 155.573C343.972 155.573 344.028 155.573 344.028 155.573C344.776 155.545 351.405 155.185 357.257 154.075C359.532 153.631 361.667 153.021 363.775 152.216C364.579 151.911 366.493 151.162 366.216 149.858C365.966 148.777 364.552 148.749 364.08 148.749C363.775 148.749 363.414 148.749 363.026 148.777C360.78 148.943 358.589 149.331 356.342 149.886C350.545 151.44 344.416 153.964 343.722 154.242Z"
        fill="#3BB2F4"
      />
      <path
        d="M299.263 182.177C299.152 180.818 297.266 180.54 295.63 180.429C295.103 180.402 294.52 180.374 293.938 180.374C291.691 180.318 289.472 180.429 287.143 180.707C279.904 181.567 272.221 183.564 270.751 183.953C270.307 184.064 270.141 184.424 270.169 184.702C270.196 185.007 270.418 185.312 270.89 185.34C271.583 185.395 275.216 185.7 279.682 185.811C282.566 185.867 285.229 185.839 287.586 185.728C290.471 185.562 293.244 185.173 295.99 184.535C297.571 184.119 299.374 183.536 299.263 182.177Z"
        fill="#91E7F2"
      />
      <path
        d="M281.207 130.994C280.569 130.967 279.682 131.216 278.489 131.716C275.882 132.825 273.441 134.129 271.001 135.71C264.733 139.788 258.437 145.253 257.743 145.891C257.355 146.224 257.466 146.585 257.494 146.696C257.577 146.973 257.854 147.14 258.132 147.14C258.243 147.14 258.354 147.112 258.492 147.056C259.352 146.724 267.118 143.644 273.608 139.982C276.132 138.568 278.434 136.986 280.625 135.183C281.901 134.129 283.232 132.797 282.539 131.632C282.344 131.355 281.956 131.022 281.207 130.994Z"
        fill="#3BB2F4"
      />
      <path
        d="M260.933 107.109C260.212 107.081 259.296 107.553 257.965 108.635C255.774 110.41 253.777 112.352 251.863 114.544C246.982 120.203 242.433 127.194 241.906 127.97C241.685 128.303 241.768 128.609 241.851 128.72C241.962 128.941 242.211 129.08 242.433 129.08C242.683 129.08 242.877 128.941 242.96 128.886C244.153 127.97 250.421 123.116 255.552 117.928C257.577 115.875 259.352 113.711 260.96 111.381C261.876 110.022 262.819 108.357 261.82 107.442C261.598 107.22 261.293 107.109 260.933 107.109Z"
        fill="#007FC8"
      />
      <path
        d="M307.583 179.403C310.163 179.458 312.52 179.431 314.628 179.32C317.235 179.181 319.704 178.82 322.172 178.238C323.614 177.905 325.279 177.35 325.168 176.074C325.057 174.798 323.337 174.548 321.839 174.465C321.368 174.437 320.841 174.41 320.286 174.41C318.289 174.354 316.292 174.465 314.212 174.715C307.556 175.519 300.317 177.406 299.512 177.627C299.069 177.738 298.902 178.071 298.93 178.377C298.958 178.682 299.18 178.987 299.651 179.015C300.289 179.015 303.562 179.292 307.583 179.403Z"
        fill="#007FC8"
      />
      <path
        d="M329.827 157.265C331.214 156.683 332.739 155.878 332.434 154.63C332.157 153.548 330.77 153.52 330.299 153.492C329.91 153.492 329.439 153.52 328.884 153.576C326.36 153.853 323.919 154.325 321.423 155.046C314.961 156.932 308.166 159.956 307.389 160.317C306.973 160.511 306.89 160.872 306.946 161.121C307.001 161.426 307.306 161.676 307.639 161.676C307.667 161.676 307.75 161.676 307.778 161.676C308.61 161.621 316.043 161.01 322.588 159.568C325.084 158.985 327.47 158.236 329.827 157.265Z"
        fill="#91E7F2"
      />
      <path
        d="M327.331 107.747C328.468 106.776 329.688 105.528 328.995 104.446C328.829 104.168 328.468 103.835 327.775 103.835C327.165 103.835 326.388 104.03 325.279 104.529C322.949 105.555 320.785 106.776 318.622 108.219C313.047 111.991 307.5 116.957 306.862 117.512C306.502 117.845 306.585 118.205 306.641 118.316C306.724 118.566 307.001 118.76 307.278 118.76C307.389 118.76 307.5 118.733 307.639 118.677C308.887 118.178 315.433 115.431 321.091 112.13C323.365 110.826 325.417 109.384 327.331 107.747Z"
        fill="#3BB2F4"
      />
      <path
        d="M305.697 125.585C305.531 125.28 305.17 124.919 304.394 124.891C303.811 124.891 303.035 125.058 302.009 125.446C299.623 126.334 297.377 127.416 295.13 128.747C289.334 132.16 283.509 136.82 282.844 137.347C282.455 137.652 282.539 138.041 282.566 138.152C282.649 138.429 282.927 138.623 283.204 138.623C283.287 138.623 283.398 138.596 283.509 138.568C284.286 138.29 291.358 135.849 297.321 132.825C299.651 131.66 301.759 130.329 303.784 128.803C305.032 127.86 306.308 126.695 305.697 125.585Z"
        fill="#91E7F2"
      />
      <path
        d="M304.2 92.7112C304.033 92.517 303.728 92.2951 303.229 92.2674C302.591 92.2396 301.731 92.6002 300.566 93.377C298.431 94.7641 296.489 96.2898 294.576 98.093C289.694 102.67 284.979 108.468 284.452 109.134C284.175 109.467 284.258 109.8 284.314 109.911C284.425 110.133 284.674 110.299 284.924 110.299C285.063 110.299 285.201 110.271 285.368 110.16C286.089 109.717 292.44 105.805 297.627 101.561C299.651 99.8962 301.426 98.1762 303.063 96.2344C304.061 95.097 305.06 93.6822 304.2 92.7112Z"
        fill="#007FC8"
      />
      <path
        d="M269.753 65.6635C269.586 65.5526 269.392 65.4971 269.17 65.4971C268.588 65.4971 267.756 65.8022 265.786 68.2712C263.54 71.0731 261.487 73.9859 259.546 77.1484C254.47 85.3876 249.811 94.8751 249.284 95.957C249.145 96.2344 249.145 96.4841 249.284 96.706C249.395 96.9002 249.617 97.0389 249.839 97.0389C250.171 97.0389 250.393 96.817 250.477 96.706C251.725 95.2357 258.159 87.4404 263.429 79.7006C265.509 76.6491 267.34 73.5975 268.976 70.3795C270.169 68.0215 270.779 66.3293 269.753 65.6635Z"
        fill="#91E7F2"
      />
      <path
        d="M280.708 78.7575C280.486 78.5633 280.209 78.4523 279.904 78.4523C279.21 78.4246 278.378 78.8962 277.213 79.8949C275.272 81.5594 273.552 83.3348 271.888 85.3599C267.645 90.5475 263.762 96.928 263.318 97.6493C263.124 97.9822 263.207 98.2596 263.263 98.3983C263.374 98.6203 263.595 98.759 263.845 98.759C264.011 98.759 264.206 98.7035 264.372 98.5648C265.01 98.0377 270.807 93.3217 275.383 88.4115C277.158 86.4973 278.683 84.5277 280.07 82.3916C280.874 81.1432 281.679 79.5897 280.708 78.7575Z"
        fill="#91E7F2"
      />
      <path
        d="M313.879 91.2411C308.831 95.652 303.95 101.311 303.395 101.949C303.09 102.282 303.201 102.615 303.257 102.726C303.368 102.976 303.617 103.142 303.867 103.142C304.006 103.142 304.144 103.114 304.311 103.031C305.032 102.615 311.522 98.9254 316.847 94.8475C318.899 93.2662 320.758 91.574 322.449 89.6876C323.448 88.5779 324.502 87.1909 323.698 86.1922C323.531 85.998 323.226 85.7483 322.671 85.7206C322.033 85.6929 321.201 86.0258 320.036 86.747C317.845 88.0231 315.848 89.5211 313.879 91.2411Z"
        fill="#3BB2F4"
      />
      <path
        d="M309.719 142.84C309.358 143.062 309.275 143.394 309.358 143.644C309.442 143.949 309.719 144.143 310.024 144.171C310.08 144.171 310.163 144.171 310.218 144.143C311.022 144.005 318.345 142.618 324.724 140.454C327.192 139.622 329.466 138.623 331.713 137.402C333.016 136.681 334.459 135.71 334.015 134.517C333.876 134.101 333.46 133.63 332.351 133.602C331.852 133.602 331.186 133.657 330.382 133.851C327.885 134.379 325.528 135.128 323.115 136.126C316.902 138.651 310.44 142.396 309.719 142.84Z"
        fill="white"
        fill-opacity="0.5"
      />
      <path
        d="M291.58 249.2C292.662 249.228 293.161 248.756 293.383 248.368C294.187 246.925 292.385 245.288 290.72 244.012C287.725 241.71 284.619 239.712 281.18 237.937C272.526 233.443 262.597 229.864 260.655 229.171C260.545 229.143 260.461 229.115 260.35 229.115C260.073 229.115 259.768 229.309 259.685 229.559C259.657 229.67 259.546 230.058 259.99 230.419C260.933 231.196 269.586 238.214 278.129 243.374C281.429 245.372 284.757 247.008 288.28 248.368C289.694 248.895 290.776 249.172 291.58 249.2Z"
        fill="#007FC8"
      />
      <path
        d="M313.741 257.911C312.576 256.94 311.466 256.052 309.497 255.053C305.337 252.945 300.455 251.447 299.485 251.142C299.429 251.114 299.318 251.114 299.263 251.114C298.986 251.114 298.708 251.28 298.597 251.558C298.486 251.835 298.597 252.168 298.819 252.362C298.986 252.529 303.201 256.163 307.417 258.771C309.192 259.853 310.523 260.518 311.993 261.018C312.604 261.24 313.103 261.323 313.519 261.351C314.379 261.378 314.767 260.99 314.961 260.657C315.599 259.492 314.212 258.299 313.741 257.911Z"
        fill="white"
        fill-opacity="0.5"
      />
      <path
        d="M320.231 197.684C321.118 197.767 321.923 197.823 322.616 197.851C323.559 197.878 324.419 197.823 325.334 197.712C325.944 197.629 327.747 197.407 327.83 196.075C327.913 194.744 326.138 194.3 325.556 194.161C324.114 193.8 322.727 193.495 320.564 193.44H320.48C315.821 193.357 310.745 194.133 309.775 194.3C309.469 194.355 309.164 194.633 309.137 194.938C309.109 195.271 309.386 195.576 309.691 195.659C310.274 195.826 315.405 197.185 320.231 197.684Z"
        fill="#91E7F2"
      />
      <path
        d="M306.807 189.307C305.392 189.029 303.95 188.807 301.704 188.863C297.016 189.029 292.024 190.083 291.053 190.305C290.748 190.361 290.471 190.666 290.471 190.999C290.471 191.332 290.748 191.637 291.053 191.692C291.275 191.748 296.656 192.885 301.676 193.135C302.009 193.163 302.314 193.163 302.619 193.163C304.227 193.19 305.448 193.107 306.779 192.885C307.362 192.774 309.192 192.441 309.192 191.11C309.192 189.778 307.389 189.418 306.807 189.307Z"
        fill="#91E7F2"
      />
      <path
        d="M354.733 173.91C355.482 173.688 357.784 173.05 357.618 171.413C357.451 169.777 355.094 169.555 354.317 169.499C353.513 169.416 352.625 169.361 351.655 169.333C350.24 169.305 348.826 169.388 347.273 169.61C340.699 170.498 333.627 172.773 332.823 173.05C332.407 173.189 332.268 173.522 332.296 173.799C332.323 174.077 332.545 174.382 332.961 174.437C332.989 174.437 337.149 174.909 342.197 175.02C344.222 175.075 346.108 175.047 347.8 174.964C350.629 174.798 352.653 174.52 354.733 173.91Z"
        fill="#91E7F2"
      />
    </svg>
  );
}

export default SvgAdultLargeMaskBg;
